import React, { useState } from "react";
import "./OnlineFeepayment.css";
import { QrReader } from "react-qr-reader";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import OnlinePageFooter from "./OnlinePageFooter";
import Singaji_logo from "../../../assests/image/Singaji_logo.svg";
const OnlineFeePayment = () => {
  const [data, setData] = useState(null);
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    aadharNo: Yup.string()
      .matches(/^\d{12}$/, "Aadhar no. must be 12 digits and number") // Ensure only 12 digits
      .required("Please enter aadhar number")
      .typeError("Aadhar number must be a number"),
  });
  const formik = useFormik({
    initialValues: {
      aadharNo: "",
    },
    validationSchema,
    onSubmit: () => {
      navigate(`/verify-otp/${formik.values.aadharNo}`);
    },
  });

  return (
    <>
      <section className="vh-100" style={{ backgroundColor: "#fff" }}>
        <form onSubmit={formik.handleSubmit}>
          <div className="container py-5 h-100">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="col-12 col-md-8 col-lg-6 col-xl-4">
                <div
                  className="card shadow-2-strong form_card"
                  style={{ borderRadius: "1rem" }}
                >
                  <div className="card-body p-5 pt-2 text-center">
                    <img
                      src={Singaji_logo}
                      alt="logo"
                      height="70px"
                      width={"70px"}
                    />
                    <h3 className="mb-2">PAY FEE</h3>
                    <span>
                      Scan your college id card or enter aadhar number
                    </span>
                    <div className="d-flex justify-content-center">
                      <div>
                        <QrReader
                          onResult={(result, error) => {
                            if (!!result) {
                              const aadharWithoutDashes = result?.text.replace(
                                /-/g,
                                ""
                              );
                              setData(aadharWithoutDashes);
                              navigate(`/verify-otp/${aadharWithoutDashes}`);
                            }

                            if (!!error) {
                              console.info(error);
                            }
                          }}
                          style={{ width: "80%", height: "80%" }}
                          constraints={{
                            facingMode: "environment", // Use back camera
                          }}
                        />

                        <div className="divider d-flex align-items-center my-1">
                          <p className="text-center fw-bold mx-3 mb-0 text-muted">
                            OR
                          </p>
                        </div>

                        <div className="form-outline pt-4 mb-3">
                          <input
                            value={formik.values.aadharNo}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            name="aadharNo"
                            type="text"
                            className="form-control form-control-lg"
                            placeholder="Aadhar Number"
                          />
                          {formik.errors.aadharNo && formik.touched.aadharNo ? (
                            <p
                              className="text-danger mt-1"
                              style={{ textAlign: "start" }}
                            >
                              {formik.errors.aadharNo}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="mt-3">
                          <button
                            className="btn"
                            type={data === null ? "submit" : "button"}
                            style={{
                              backgroundColor: "#f9a826",
                              color: "#fff",
                              padding: "10px 20px",
                              borderRadius: "5px",
                              border: "none",
                              cursor: "pointer",
                              marginTop: "20px",
                              width: "100%",
                            }}
                            onClick={() => {
                              data === null
                                ? formik.handleSubmit()
                                : navigate(`/verify-otp/${data}`);
                            }}
                          >
                            GET OTP
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <OnlinePageFooter />
      </section>
    </>
  );
};

export default OnlineFeePayment;
