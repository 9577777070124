import React, { useState } from "react";
import "../styles/Table.css";
import "../styles/HeaderDropdown.css";
import { Fragment, useMemo } from "react";
import {
  useTable,
  useFilters,
  useSortBy,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from "react-table";
import updown_sort from "../../assests/image/updown_sort.svg";

import { GlobalFilter } from "../tableComponents/GlobalFilter";
import filtericon from "../../assests/image/AccountIcons/filter.svg";

import {
  CDropdown,
  CDropdownMenu,
  CDropdownToggle,
  CPopover,
} from "@coreui/react";
import AllUrl from "../../../redux/constants/url";
import { connect } from "react-redux";
import SkeletonColor from "../../../helpers/Skeletrone";
import Pagination from "../../assests/common/Pagination";
import { DateRangePicker, Tooltip, Whisper } from "rsuite";
import rightArrow from "../../assests/image/right_arrow_icon.svg";
import NoDataFound from "../../assests/common/NoDataFound";
import onlineFeesListTableData from "../../../redux/actionDispatcher/account/onlineFeesListTableDispatcher";
import dateIcon from "../../assests/image/AccountIcons/DateIcon.svg";
import { useLocation } from "react-router-dom";
import { CSVLink } from "react-csv";
import { TableCheckbox } from "../tableComponents/TableCheckbox";
import Icon_feather_download from "../../assests/image/AccountIcons/receipt_download_icon.svg";

// =============== Table data filteration =========================
const MultipleFilter = (rows, accessor, filterValue) => {
  const arr = [];
  rows.forEach((val) => {
    if (filterValue.includes(val.original[accessor])) arr.push(val);
  });
  return arr;
};

function setFilteredParams(filterArr, val) {
  if (filterArr.includes(val)) {
    filterArr = filterArr.filter((n) => {
      return n !== val;
    });
  } else filterArr.push(val);

  if (filterArr.length === 0) filterArr = undefined;
  return filterArr;
}

// ====================== Column filter =====================
function SelectColumnFilter({
  column: { filterValue = [], setFilter, preFilteredRows, id },
}) {
  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  let offsetObj = [0, 0];

  if (id === "year") offsetObj = [60, 18];
  if (id === "installmentNo") offsetObj = [73, 18];
  if (id === "status") offsetObj = [60, 18];
  let name = id;

  switch (id) {
    case "year":
      name = "Year";
      break;
    case "installmentNo":
      name = "Installment No.";
      break;
    case "status":
      name = "Status";
      break;

    default:
      break;
  }

  return (
    <Fragment>
      <div
        onClick={(e) => {
          e.preventDefault();
        }}
        className="d-flex justify-content-end"
      >
        <CPopover
          offset={offsetObj}
          content={
            <div className="">
              {options.map((option, i) => {
                let option_label = option;

                if (id === "is_active") {
                  if (option === "true") option_label = "Active";
                  else option_label = "Deactive";
                }

                return (
                  <Fragment key={i}>
                    <div
                      id={`${id}`}
                      style={{ height: "30px", cursor: "pointer" }}
                      className="filter_btn_hover p-1 pt-2 my-1 d-flex align-items-center "
                    >
                      <label
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="font-medium text-gray-700 d-flex align-items-center cursor-pointer"
                        style={{ cursor: "pointer" }}
                      >
                        <input
                          checked={filterValue.includes(option)}
                          type="checkbox"
                          className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded mr-1"
                          id={option}
                          name={option}
                          value={option}
                          style={{ cursor: "pointer" }}
                          onChange={(e) => {
                            setFilter(
                              setFilteredParams(filterValue, e.target.value)
                            );
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        ></input>

                        {option_label}
                      </label>
                    </div>
                  </Fragment>
                );
              })}
            </div>
          }
          placement="right"
        >
          <div className="btn-group filter_btn_hover">
            <button
              onClick={(e) => {
                e.preventDefault();
              }}
              className="btn filter_btn"
            >
              {name}
            </button>
            <img
              src={rightArrow}
              alt=">"
              width="6px"
              style={{
                marginTop: "4px",
                marginRight: "10px",
              }}
            />
          </div>
        </CPopover>
      </div>
    </Fragment>
  );
}

function OnlineFeesListTable({ feesReceipt, fetchData }) {
  const token = localStorage.getItem("token");
  const location = useLocation();

  // Extract query parameters
  const searchParams = new URLSearchParams(location.search);
  const paramDateFrom = searchParams.get("dateFrom");
  const paramDateTo = searchParams.get("dateTo");

  const dt = new Date();
  dt.setDate(1);
  const [date, setDate] = useState({
    a: paramDateFrom ? new Date(paramDateFrom) : dt,
    b: paramDateTo ? new Date(paramDateTo) : new Date(),
  });

  const [MoneyCount, setMoneyCount] = useState({
    FailedAmount: 0,
    InitiatedAmount: 0,
    SuccessAmount: 0,
  });

  const CIcon = () => {
    return (
      <>
        <img style={{ marginLeft: "-180px" }} src={dateIcon} alt="date" />
      </>
    );
  };

  let dateFrom = `${date.a.getFullYear()}-${(date.a.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${date.a.getDate().toString().padStart(2, "0")}`;
  let dateTo = `${date.b.getFullYear()}-${(date.b.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${date.b.getDate().toString().padStart(2, "0")}`;

  React.useEffect(() => {
    var config = {
      method: "GET",
      url: `${AllUrl.onlineFeesListTable}?dateFrom=${dateFrom}&dateTo=${dateTo}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    fetchData(config);
    // settable_data(table_data.table_data);
    // eslint-disable-next-line
  }, [date]);

  const columns = React.useMemo(
    () => [
      {
        header: "S.No",
        accessor: "S.N",
        Cell: ({ row: { original, index } }) => {
          return index + 1;
        },
        Filter: "",
        filter: "",
      },
      {
        header: "Date",
        accessor: "date",
        Filter: "",
        filter: "",
      },
      {
        header: "Name",
        accessor: "studentName",
        Filter: "",
        filter: "",
      },
      {
        header: "Year",
        accessor: "year",

        Cell: ({ row: { original } }) => <span>{`${original.year}`}</span>,

        Filter: SelectColumnFilter,
        filter: MultipleFilter,
      },
      {
        header: "Amount",
        accessor: "amount",
        Filter: "",
        filter: "",
      },
      {
        header: "Installment No.",
        accessor: "installmentNo",

        Cell: ({ row: { original } }) => (
          <span>{`${original.installmentNo}`}</span>
        ),

        Filter: SelectColumnFilter,
        filter: MultipleFilter,
      },
      {
        header: "Status",
        accessor: "status",
        Cell: ({ row: { original } }) => <span>{`${original.status}`}</span>,

        Filter: SelectColumnFilter,
        filter: MultipleFilter,
      },
      {
        header: "Download",
        accessor: "receiptS3Url ",
        Filter: "",
        filter: "",
        Cell: ({ row: { original } }) => (
          <Whisper
            placement="top"
            controlId="control-id-hover"
            trigger="hover"
            speaker={
              <Tooltip>
                {original?.status === "SUCCESS"
                  ? "Download Receipt"
                  : "No Receipt Available"}
              </Tooltip>
            }
          >
            {original?.status === "SUCCESS" ? (
              <div>
                <a
                  href={
                    original?.receiptS3Url 
                  }
                  rel="noreferrer"
                  target="_blank"
                >
                  <img
                    className="mb-1"
                    src={Icon_feather_download}
                    style={{ cursor: "pointer" }}
                    alt="_blank"
                  />
                </a>
              </div>
            ) : (
              <div>
                <img
                  className="mb-1"
                  src={Icon_feather_download}
                  style={{ cursor: "not-allowed" }}
                  alt="_blank"
                />
              </div>
            )}
          </Whisper>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    gotoPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    setPageSize,
    pageOptions,
    selectedFlatRows,
    state,
    pageCount,
    setGlobalFilter,
    rows,
    preGlobalFilteredRows,
    prepareRow,
  } = useTable(
    { columns, data: feesReceipt.table_data },

    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: "selection",
            header: ({ getToggleAllRowsSelectedProps }) => (
              <TableCheckbox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }) => (
              <TableCheckbox {...row.getToggleRowSelectedProps()} />
            ),
          },
          ...columns,
        ];
      });
    }
  );

  const { globalFilter, pageSize, pageIndex } = state;

  var idData = [];
  var exportCsv = [];
  var fileName = `OnlineFeesList ${dateFrom} TO ${dateTo}`;

  // eslint-disable-next-line no-unused-vars
  const checkboxData = JSON.stringify(
    {
      selectedFlatRows: selectedFlatRows.forEach((row) => {
        let data = Object.assign({}, row.original);
        idData.push(data.id);
        exportCsv.push(data);
      }),
    },
    null,
    2
  );

  React.useEffect(() => {
    let data = rows;
    let failedAmount = 0;
    let initiatedAmount = 0;
    let successAmount = 0;
    data.forEach((ele) => {
      ele = ele.original;
      successAmount += ele?.status === "SUCCESS" && parseInt(ele?.amount);
      failedAmount += ele?.status === "FAILED" && parseInt(ele?.amount);
      initiatedAmount += ele?.status === "INITIATED" && parseInt(ele?.amount);
    });

    setMoneyCount((val) => {
      return {
        FailedAmount: failedAmount,
        InitiatedAmount: initiatedAmount,
        SuccessAmount: successAmount,
      };
    });
  }, [rows]);

  return feesReceipt.loading ? (
    <SkeletonColor></SkeletonColor>
  ) : (
    <Fragment>
      <div className="container-fluid">
        <div
          style={{
            position: "sticky",
            top: "80px",
            width: "100%",
            paddingTop: "10px",
            paddingBottom: "10px",
            backgroundColor: "#f4f7fc",
            zIndex: "6",
          }}
        >
          <div
            className="d-flex row Stu-Acc-info "
            style={{
              color: "rgb(90, 96, 127)",
              margin: "Auto",
              height: "70px",
            }}
          >
            <div className="col info-col m-2">
              <h5 style={{ marginTop: "12px" }}>
                {MoneyCount.SuccessAmount
                  ? MoneyCount.SuccessAmount.toLocaleString("en-IN")
                  : "-"}{" "}
                <br /> <p>{"Successful Amount"}</p>{" "}
              </h5>
            </div>

            <div className="col info-col m-2">
              <h5 style={{ marginTop: "12px" }}>
                {MoneyCount.FailedAmount
                  ? MoneyCount.FailedAmount.toLocaleString("en-IN")
                  : "-"}{" "}
                <br /> <p>{"Failed Amount"}</p>{" "}
              </h5>
            </div>

            <div className="col info-col m-2">
              <h5 style={{ marginTop: "12px" }}>
                {MoneyCount.InitiatedAmount
                  ? MoneyCount.InitiatedAmount.toLocaleString("en-IN")
                  : "-"}{" "}
                <br /> <p>{"Initiated Amount"}</p>{" "}
              </h5>
            </div>
          </div>

          <div className="d-flex mt-3 mx-0">
            <div className="d-flex">
              <select
                className="form-select table_select_row_options"
                value={pageSize}
                onChange={(e) => setPageSize(Number(e.target.value))}
              >
                {[10, 25, 50, 100].map((pageSize) => (
                  <option value={pageSize} key={pageSize}>
                    Show Entries {pageSize}
                  </option>
                ))}
              </select>
              <DateRangePicker
                caretAs={CIcon}
                placement="auto"
                value={[date.a, date.b]}
                onClean={(e) => {
                  e.preventDefault();
                }}
                onChange={(value) => {
                  if (!value) {
                    return;
                  }
                  var a = value[0];
                  var b = value[1];
                  setDate({
                    a,
                    b,
                  });
                }}
                appearance="default"
                className="stu-acc-table ms-2"
                placeholder="TO"
                style={{ width: 230 }}
              />
              <div className="btn-group  ml-3">
                <button
                  className="btn  btn-sm download-btn dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Download
                </button>
                <div className="dropdown-menu mt-1">
                  <div>
                    <div>
                      <CSVLink
                        className="dropdown-item"
                        style={{ fontWeight: "bold" }}
                        data={exportCsv}
                        filename={fileName}
                      >
                        Excel
                      </CSVLink>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex ml-auto me-1">
              <div
                className="d-flex mr-2"
                style={{
                  height: "40px",
                  width: "42px",
                  backgroundColor: "#fff",
                  borderRadius: "3px",
                  border: "1px solid #EDEDED",
                }}
              >
                <CDropdown variant="nav-item" style={{ color: "white" }}>
                  <CDropdownToggle
                    placement="bottom-end"
                    className="py-0"
                    caret={false}
                  >
                    <Whisper
                      placement="top"
                      controlId="control-id-hover"
                      trigger="hover"
                      speaker={<Tooltip>Filter Data .</Tooltip>}
                    >
                      <img
                        src={filtericon}
                        alt=""
                        style={{
                          height: "23px",
                          width: "35px",
                          marginTop: "-35px",
                          marginLeft: "-13px",
                        }}
                      />
                    </Whisper>
                  </CDropdownToggle>

                  <CDropdownMenu
                    component={"div"}
                    className="pt-0 filter-dropdown-menu"
                    placement="bottom-end"
                  >
                    <div className="p-lg-2 pb-0">
                      {headerGroups.map((headerGroup) => (
                        <div
                          style={{ display: "flex flex-column" }}
                          {...headerGroup.getHeaderGroupProps()}
                        >
                          {headerGroup.headers.map((column, i) => (
                            <div
                              key={i}
                              style={{
                                display: "block",
                                justifyContent: "center",
                              }}
                            >
                              {column.canFilter
                                ? column.render("Filter")
                                : null}
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  </CDropdownMenu>
                </CDropdown>
              </div>
              <div className="ml-auto me-4">
                <GlobalFilter
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  filter={globalFilter}
                  setFilter={setGlobalFilter}
                />
              </div>
            </div>
          </div>
        </div>

        <table {...getTableProps()} id="customers">
          <thead
            style={{
              position: "sticky",
              top: "135px",
              width: "100%",
              backgroundColor: "#f4f7fc",
              zIndex: "5",
            }}
          >
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("header")}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <img
                            src={updown_sort}
                            style={{ marginLeft: "5px" }}
                            alt=""
                          />
                        ) : (
                          <img
                            src={updown_sort}
                            style={{ marginLeft: "5px" }}
                            alt=""
                          />
                        )
                      ) : (
                        ""
                      )}
                      {column.isSorted ? (column.isSortedDesc ? "" : "") : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <NoDataFound rows={rows} />
        {/* ================= passing table page data too the pagintaion component ================= */}
        <Pagination
          data={rows}
          rows={rows}
          page={page}
          pageIndex={pageIndex}
          pageCount={pageCount}
          pageSize={pageSize}
          canPreviousPage={canPreviousPage}
          previousPage={previousPage}
          pageOptions={pageOptions}
          gotoPage={gotoPage}
          canNextPage={canNextPage}
          nextPage={nextPage}
        />
      </div>
    </Fragment>
  );
}

//Getting the state from the store
const mapStateToProps = (state) => {
  return {
    feesReceipt: state.onlineFeesListData,
  };
};

//passing the userData in fetchData function and also dispatch method
const mapDispatchToProps = (dispatch) => {
  return {
    fetchData: (data) => dispatch(onlineFeesListTableData(data)),
  };
};

//Connecting the component to our store
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OnlineFeesListTable);
