import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import img1 from "../assests/site-images/1.jpg";
import img2 from "../assests/site-images/2.jpg";
import img3 from "../assests/site-images/3.jpg";
import img4 from "../assests/site-images/4.jpg";
import img5 from "../assests/site-images/5.jpg";
import img6 from "../assests/site-images/6.jpg";
import logo from "../assests/image/logoimage.png";

const SsismIndex = () => {
  const navigate = useNavigate();
  useEffect(() => {
    // Scroll event handler to add or remove the 'navbar-fixed-top' class
    const handleScroll = () => {
      const navbar = document.querySelector(".navbar-default");
      if (window.scrollY > 670) {
        navbar.classList.add("navbar-fixed-top");
      } else {
        navbar.classList.remove("navbar-fixed-top");
      }
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // List of script sources
    const scripts = [
      "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.2&appId=947009608659338&autoLogAppEvents=1",
      "https://ssism.org/js/jquery-1.11.1.js",
      "https://ssism.org/js/modernizr-2.6.2.min.js",
      "https://ssism.org/js/bootstrap.min.js",
      "https://ssism.org/js/rs-plugin/js/jquery.themepunch.plugins.min.js",
      "https://ssism.org/js/rs-plugin/js/jquery.themepunch.revolution.js",
      "https://ssism.org/js/jquery.shuffle.min.js",
      "https://ssism.org/js/mmenu/js/jquery.mmenu.min.js",
      "https://ssism.org/js/owl/js/owl.carousel.min.js",
      "https://ssism.org/js/wow.min.js",
      "https://cdnjs.cloudflare.com/ajax/libs/waypoints/2.0.3/waypoints.min.js",
      "https://ssism.org/js/jquery.counterup.min.js",
      "https://ssism.org/js/jquery.easing.min.js",
      "https://ssism.org/js/scrolling-nav.js",
      "https://ssism.org/js/smoothscroll.min.js",
      "https://ssism.org/js/script.js",
      "https://ssism.org/js/email.js",
    ];

    // Function to dynamically load a script
    const loadScript = (
      src,
      async = true,
      defer = false,
      crossorigin = null
    ) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = src;
        script.async = async;
        script.defer = defer;
        if (crossorigin) script.crossOrigin = crossorigin;
        script.onload = () => resolve();
        script.onerror = () => reject();
        document.body.appendChild(script);
      });
    };

    // Load each script sequentially
    scripts
      .reduce(
        (promise, src) => promise.then(() => loadScript(src)),
        Promise.resolve()
      )
      .catch((error) => console.error("Failed to load a script:", error));

    // Cleanup: Remove scripts on unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
      scripts.forEach((src) => {
        const script = document.querySelector(`script[src="${src}"]`);
        if (script) document.body.removeChild(script);
      });
    };
  }, []);
  return (
    <div>
      {/* <style dangerouslySetInnerHTML={{__html: "\n                .image-container {\n                    position: relative;\n    }\n                .donate-btn {\n                    position: absolute;\n                top: 90%;\n                left: 90%;\n                transform: translate(-50%, -50%);\n    }\n            " }} /> */}
      <style
        dangerouslySetInnerHTML={{
          __html:
            "\n.image-container {\n  position: relative;\n}\n\n.donate-btn {\n  position: absolute;\n  top: 90%; \n  left: 90%;\n  transform: translate(-50%, -50%);\n  color: white !important;\n  padding: 10px 20px;\n  font-size: 1.2rem;\n}\n\n@media (max-width: 576px) {\n  /* Adjust button size and position on mobile screens */\n  .donate-btn {\n    padding: 1px 0px;\n top: 85%;\n    left: 80%;\n    transform: translate(-50%, -50%);\n    font-size: 1rem;\n  }\n}\n",
        }}
      />

      <html class="no-js">
        <head>
          <meta charSet="utf-8" />
          <title>SSISM</title>
          <meta name="description" content />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link
            rel="shortcut icon"
            type="image/png"
            href="https://ssism.org/jsimg/webimg/favicon.png"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="https://ssism.org/css/vendor/settings.css"
            media="screen"
          />
          <link
            rel="stylesheet"
            href="https://ssism.org/css/vendor/extralayer.css"
          />
          <link
            rel="stylesheet"
            href="https://ssism.org/css/vendor/flaticon.css"
          />
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
          />
          <link
            rel="stylesheet"
            href="https://ssism.org/css/vendor/owl.carousel.css"
          />
          <link
            rel="stylesheet"
            href="https://ssism.org/css/vendor/owl.theme.default.css"
          />
          <link
            rel="stylesheet"
            href="https://ssism.org/css/vendor/owl.theme.css"
          />
          <link
            type="text/css"
            rel="stylesheet"
            href="https://ssism.org/css/vendor/jquery.mmenu.css"
          />
          <link
            rel="stylesheet"
            href="https://ssism.org/css/vendor/bootstrap.css"
          />
          <link
            href="https://fonts.googleapis.com/css?family=Roboto+Condensed:300italic,400italic,700italic,400,300,700"
            rel="stylesheet"
            type="text/css"
          />
          <link
            href="https://fonts.googleapis.com/css?family=Open+Sans:400italic,600italic,400,300,600,700"
            rel="stylesheet"
            type="text/css"
          />
          <link
            rel="stylesheet"
            href="https://ssism.org/css/vendor/animate.css"
          />
          <link
            rel="stylesheet"
            href="https://ssism.org/css/vendor/hover.css"
          />
          <link
            rel="stylesheet"
            href="https://ssism.org/css/vendor/style.css"
          />
          <link
            rel="stylesheet"
            href="https://ssism.org/css/vendor/responsive.css"
          />
          <style
            dangerouslySetInnerHTML={{
              __html:
                "\n@media  only screen and (max-width: 480px) {\n  .logoclass \n  {\n    margin-top: -15px;\n  }\n}\n@media  only screen and (min-width: 481px) {\n  .logoclass \n  {\n    margin-top: -22px;\n  }\n}\n",
            }}
          />
          <style
            dangerouslySetInnerHTML={{
              __html:
                "\n    .images{display: flex;flex-wrap: wrap;   border: 2px solid #ccc;}\n    .slot{width: 50%;}\n    img{display: block;width: 100%;height: auto;}\n  ",
            }}
          />
        </head>

        <body>
          <div id="fb-root"></div>
          <header>
            <div
              className="container-fluid d-flex align-items-center p-0 m-0"
              style={{
                backgroundColor: "#FFFFFF",
                minHeight: "85px",
              }}
            >
              <div className="container-fluid container-md">
                <div className="row d-flex justify-content-between align-items-center">
                  <div className="col-6">
                    <div>
                      <img
                        src={logo}
                        alt="logo"
                        style={{
                          height: "60px",
                          width: "200px",
                          cursor: "pointer",
                        }}
                        onClick={() => navigate("/")}
                      />
                    </div>
                  </div>
                  <div className="col-6 d-flex justify-content-end align-items-center">
                    <div className="mx-2">
                      <button
                        className="btn"
                        onClick={() => navigate("/login")}
                        style={{
                          backgroundColor: "#f9a826",
                          color: "#fff",
                          // padding: "px 20px",
                          borderRadius: "5px",
                          border: "none",
                          cursor: "pointer",
                          width: "100%",
                        }}
                      >
                        Login
                      </button>
                    </div>
                    <div className="mx-2">
                      <button
                        className="btn"
                        onClick={() => navigate("/online-fee-payment")}
                        style={{
                          backgroundColor: "#f9a826",
                          color: "#fff",
                          // padding: "10px 20px",
                          borderRadius: "5px",
                          border: "none",
                          cursor: "pointer",
                          width: "100%",
                        }}
                      >
                        Pay Fee
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>
          <div className="d-flex flex-column">
            <div className="container-fluid">
              <div className="row">
                {[img1, img2, img3, img4, img5, img6].map((image, index) => (
                  <div className="col-12 mb-3" key={index}>
                    <img
                      src={image}
                      alt={`Image ${index + 1}`}
                      className="img-fluid w-100 rounded"
                    />
                  </div>
                ))}
              </div>
            </div>

            <footer className="pt-4">
              <div className="footer-wrapper section-padding">
                <div className="container">
                  <div className="row">
                    <div
                      className="wow zoomIn col-xs-12 col-sm-6 col-md-3 animated"
                      style={{ visibility: "visible", animationName: "zoomIn" }}
                    >
                      <p className="footer-heading">About Us</p>
                      <ul className="footermenu">
                        <li>
                          <a href="/aboutUs">Who We Are</a>
                        </li>
                      </ul>
                    </div>

                    <div
                      className="wow zoomIn col-xs-12 col-sm-6 col-md-3 animated"
                      style={{ visibility: "visible", animationName: "zoomIn" }}
                    >
                      <p className="footer-heading">Other Link</p>
                      <ul className="footermenu">
                        <li>
                          <Link to="/termsConditions">
                            Terms And Conditions
                          </Link>
                        </li>
                        <li>
                          <Link to="/privacy">Privacy policy</Link>
                        </li>
                        <li>
                          <Link to="/refundsCancellations">
                            Refunds/Cancellations
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div
                      className="wow zoomIn col-xs-12 col-sm-6 col-md-3 animated"
                      style={{ visibility: "visible", animationName: "zoomIn" }}
                    >
                      <p className="footer-heading">find us</p>
                      <ul className="footercontact">
                        <li>
                          <i className="flaticon-phone16" />
                          <span>phone:</span>
                          <a href="tel:91 9893371756"> +91 9893371756</a>
                        </li>
                        <li>
                          <i className="flaticon-email21" />
                          <span>e-mail:</span>
                          <a href="mailto:contact@ssism.org">
                            {" "}
                            contact@ssism.org
                          </a>
                        </li>
                        <li>
                          <i className="flaticon-world91" />
                          <span>web:</span>
                          <a href="http://ssism.org/"> www.ssism.org</a>
                        </li>
                      </ul>
                      <i className="flaticon-home78" />
                    </div>
                    <div
                      className="wow zoomIn col-xs-12 col-sm-6 col-md-12 animated"
                      style={{ visibility: "visible", animationName: "zoomIn" }}
                    >
                      <ul className="footercontact">
                        <li>
                          <i className="flaticon-mainpage" />
                          <span>address:</span> NH 59-A,Gram-Sandalpur, Tehsil
                          Khategaon, District Dewas, MP, India, Pin Code –
                          455336{" "}
                        </li>
                      </ul>
                      <i className="flaticon-home78" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer-bottom">
                <div className="container">
                  <div className="row">
                    <div
                      className="wow zoomIn col-xs-12 animated"
                      style={{ visibility: "visible", animationName: "zoomIn" }}
                    >
                      <p>
                        © 2024 All rights reserved. <span>SSISM</span> by{" "}
                        <a href="https://singaji.in">
                          Singaji Software Solutions
                        </a>
                      </p>
                      <div className="backtop  pull-right">
                        <i
                          className="fa fa-angle-up back-to-top"
                          style={{ display: "none" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </footer>
          </div>
        </body>
      </html>
    </div>
  );
};

export default SsismIndex;
