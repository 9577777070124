import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import AllUrl from "../../../../redux/constants/url";
import axios from "axios";
import Loader from "../../../assests/common/Loader";
import LoaderButton from "../../../assests/common/LoaderButton";
import Swal from "sweetalert2";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Singaji_logo from "../../../assests/image/Singaji_logo.svg";
import OnlinePageFooter from "./OnlinePageFooter";
const VerifyOtpOnlineFee = () => {
  const { aadharNo } = useParams();
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [StudentProfileData, setStudentProfileData] = useState();
  const [timeLeft, setTimeLeft] = useState();
  const [lodingForOtpRequest, setLoadingForOtpRequest] = useState(false);

  const [loadingForOtpVerification, setLoadingForOtVerification] =
    useState(false);
  const [verifyResp, setVerifyResp] = useState();
  const [otpReqResp, setOtpReqResp] = useState();
  let fetchData = async () => {
    setLoadingForOtpRequest(true);
    var config = {
      method: "POST",
      url: AllUrl?.generateOtpSend,
      data: {
        aadharNo: aadharNo,
      },
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    try {
      var result = await axios(config);
      if (result?.status === 200) {
        setOtpReqResp(result?.status);
        setTimeLeft(result?.data?.expirationTime);
        setStudentProfileData(result?.data);
        setLoadingForOtpRequest(false);
      }
    } catch (error) {
      setOtpReqResp(error?.response?.status);
      if (error?.response?.status === 400) {
        setLoadingForOtpRequest(false);
        Swal.fire({
          title: "Not Found",
          text: "Please check your aadhar number and try again.",
          icon: "error",
          showCancelButton: true,
          confirmButtonText: "Retry",
          cancelButtonText: "Go Back",
          reverseButtons: true,
          cancelButtonColor: "gray",
          confirmButtonColor: "#4f83df",
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then((result) => {
          if (result.isConfirmed) {
            // Action for "Retry" button: reload the page
            window.location.reload(); // Reload the current page
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            // Action for "Go Back" button: navigate to a specific URL
            window.location.href = "/online-fee-payment";
          }
        });
      } else {
        setLoadingForOtpRequest(false);
        Swal.fire({
          title: "Error",
          text: "Internal server error, please try again.",
          icon: "error",
          showCancelButton: true,
          confirmButtonText: "OK",
          reverseButtons: true,
          cancelButtonColor: "gray",
          confirmButtonColor: "#4f83df",
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            // Action for "Go Back" button: navigate to a specific URL
            window.location.href = "/online-fee-payment";
          }
        });
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const validationSchema = Yup.object({
    enteredOTP: Yup.string()
      .matches(/^\d{6}$/, "OTP must be 6 digits and number") // Ensure only 6 digits
      .required("Please Enter OTP")
      .typeError("OTP must be a number"),
  });
  const formik = useFormik({
    initialValues: {
      enteredOTP: "",
    },
    validationSchema,
    onSubmit: async () => {
      setLoadingForOtVerification(true);
      var config = {
        method: "POST",
        url: AllUrl?.verifyOtpSend,
        data: {
          encryptedOTP: StudentProfileData?.encryptedOTP,
          enteredOTP: formik.values.enteredOTP,
        },
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      try {
        var result = await axios(config);
        if (result?.status === 200) {
          localStorage.setItem("verifiedOTP", StudentProfileData?.encryptedOTP);
          navigate(`/fee-details/${aadharNo}`);
          setLoadingForOtVerification(false);
        }
      } catch (error) {
        setLoadingForOtVerification(false);
        setVerifyResp(error?.response?.status);
      }
    },
  });
  const getIncreptedMobile = (mobile) => {
    if (mobile) {
      const mobileStr = mobile?.toString(); // Convert to string in case it's a number
      const firstTwo = mobileStr?.slice(0, 2); // First two digits
      const lastFour = mobileStr?.slice(-4); // Last four digits
      const hiddenPart = "*"?.repeat(mobileStr?.length - 6); // Middle part replaced with '*'

      return `${firstTwo}${hiddenPart}${lastFour}`;
    } else {
      return null;
    }
  };

  useEffect(() => {
    // Exit early if the time is already zero
    if (timeLeft <= 0) return;

    // Set up a timer to decrease the time by 1000 milliseconds (1 second) every interval
    const timerId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1000); // Decrease by 1000ms (1 second)
    }, 1000);

    // Cleanup interval when the component is unmounted or when timeLeft changes
    return () => clearInterval(timerId);
  }, [timeLeft]);

  const formatTime = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000); // Convert milliseconds to seconds
    const minutes = Math.floor(totalSeconds / 60); // Get the full minutes
    const remainingSeconds = totalSeconds % 60; // Get the remaining seconds
    return `${minutes}:${
      remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds
    }`;
  };

  return (
    <>
      {!lodingForOtpRequest && otpReqResp === 200 && (
        <>
          <div
            className="container-fluid d-flex justify-content-center align-items-center py-2"
            style={{ height: "100vh" }}
          >
            {/* {!lodingForOtpRequest && otpReqResp === 200 && ( */}
            {/* <> */}
            <div className="row">
              <div className="card p-0 my-4" style={{ maxWidth: "400px" }}>
                <div className="card-header d-flex justify-content-between align-items-center py-0">
                  <div>
                    <h3>Verify OTP</h3>
                  </div>
                  <div>
                    <img
                      src={Singaji_logo}
                      alt="logo"
                      height="70px"
                      width={"70px"}
                    />
                  </div>
                </div>
                <div className="card-body">
                  <div>
                    <p>
                      Please enter the OTP sent to your registered mobile
                      numbers on WhatsApp{" "}
                      <WhatsAppIcon style={{ color: "#28c144" }} /> :{" "}
                      {getIncreptedMobile(StudentProfileData?.number1)}{" "}
                      {getIncreptedMobile(StudentProfileData?.number2) && "And"}{" "}
                      {getIncreptedMobile(StudentProfileData?.number2)}
                    </p>
                  </div>
                  {verifyResp && verifyResp !== 200 && (
                    <div className="alert alert-danger py-2" role="alert">
                      {verifyResp === 400 && "Invalid OTP!"}
                      {verifyResp === 500 && "Internal Server Error!"}
                      {verifyResp &&
                        verifyResp !== 500 &&
                        verifyResp !== 400 &&
                        "Something went wrong!, please try again"}
                    </div>
                  )}
                  <form onSubmit={formik.handleSubmit}>
                    <div className="form-group">
                      <input
                        value={formik.values.enteredOTP}
                        autoComplete="off"
                        onChange={formik.handleChange}
                        disabled={loadingForOtpVerification}
                        onBlur={formik.handleBlur}
                        name="enteredOTP"
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="Enter OTP"
                      />
                      {formik.errors.enteredOTP && formik.touched.enteredOTP ? (
                        <div
                          className="text-danger fs-6"
                          style={{ textAlign: "start" }}
                        >
                          {formik.errors.enteredOTP}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="d-flex justify-content-between">
                      <div>
                        <p>
                          OTP valid upto:{" "}
                          <span
                            style={{
                              color: "#1e59d8b8",
                              fontWeight: "bold",
                            }}
                          >
                            {formatTime(timeLeft)}
                          </span>
                        </p>
                      </div>
                      <div>
                        <button
                          type="button"
                          disabled={timeLeft !== 0}
                          style={{
                            backgroundColor: "transparent",
                            color: "#1e59d8b8",
                          }}
                          onClick={fetchData}
                        >
                          Resend OTP?
                        </button>
                      </div>
                    </div>
                    <div className="form-group">
                      <button
                        className="btn"
                        type="submit"
                        disabled={
                          formik?.values?.enteredOTP?.length !== 6 ||
                          loadingForOtpVerification
                        }
                        style={{
                          backgroundColor: "#f9a826",
                          color: "#fff",
                          padding: "10px 20px",
                          borderRadius: "5px",
                          border: "none",
                          cursor: "pointer",
                          marginTop: "20px",
                          width: "100%",
                        }}
                      >
                        {loadingForOtpVerification ? (
                          <LoaderButton />
                        ) : (
                          "Verify OTP"
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {/* </> */}
            {/* )}{" "} */}
          </div>
          <OnlinePageFooter />
        </>
      )}
      {lodingForOtpRequest && <Loader fullPage={true} />}
    </>
  );
};

export default VerifyOtpOnlineFee;
