import React from "react";
import "./styles/Loader.css";

// ========================= skeleton loader on the tables ======================

function Loader({ fullPage }) {
  return (
    <div
      style={{
        display: "flex",
        position: "fixed",
        minHeight: "100%",
        minWidth: "100%",
        zIndex: "100000",
        opacity: "0.5",
      }}
    >
      <div
        className="lds-roller"
        style={{
          position: "absolute",
          left: fullPage ? "50%" : "35%",
          top: fullPage ? "50%" : "40%",
          zIndex: "100000",
        }}
      >
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}

export default Loader;
