import axios from 'axios';
import * as React from "react";
import { Fragment, useEffect, useState } from 'react';
import {
    useAsyncDebounce, useFilters, useGlobalFilter,
    usePagination,
    useRowSelect, useSortBy, useTable
} from "react-table";
import { ToastContainer } from "react-toastify";
import rightArrow from "../../components/assests/image/right_arrow_icon.svg";
import allUrls from '../../redux/constants/url';

import {
    CDropdown,
    CDropdownMenu,
    CDropdownToggle,
    CPopover
} from "@coreui/react";
import { CSVLink } from "react-csv";
import filtericon from "../../components/assests/image/AccountIcons/filter.svg";

import { useMemo } from 'react';
import SkeletonColor from "../../helpers/Skeletrone";
import NoDataFound from "../assests/common/NoDataFound";
import Pagination from "../assests/common/Pagination";
import updown_sort from "../assests/image/updown_sort.svg";
import "./styles/Table.css";
import { TableCheckbox } from "./tableComponents/TableCheckbox";


// Define a default UI for filtering
function GlobalFilter({ filter, setFilter, preGlobalFilteredRows }) {
    // const count = preGlobalFilteredRows.length;
    // const [value, setValue] = React.useState(filter);
    const onChange = useAsyncDebounce((value) => {
        setFilter(value || undefined);
    }, 1);

    return (
        <>
            <input
                style={{
                    width: "270px",
                    height: "41px",
                    outline: "none",
                    border: "1px solid #7979792b",
                    padding: "5px",
                    borderRadius: "4px",
                    paddingLeft: "12px",
                }}
                type="search"
                value={filter || ""}
                onChange={(e) => {
                    // setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder="Search..."
            />
            <i
                style={{
                    marginLeft: "-31px",
                    alignSelf: "center",
                    marginBottom: "7px",
                    color: "rgb(90, 96, 127,0.7)",
                }}
                className="fas fa-search"
            ></i>
        </>
    );
}


// import { baseUrl } from "../../redux/constants/url";
export const MultipleFilter = (rows, accessor, filterValue) => {
    const arr = [];
    rows.forEach((val) => {
        if (filterValue.includes(val.original[accessor])) arr.push(val);
    });
    return arr;
};

function setFilteredParams(filterArr, val) {
    if (filterArr.includes(val)) {
        filterArr = filterArr.filter((n) => {
            return n !== val;
        });
    } else filterArr.push(val);

    if (filterArr.length === 0) filterArr = undefined;
    return filterArr;
}

function SelectColumnFilter({
    column: { filterValue = [], setFilter, preFilteredRows, id },
}) {
    const options = useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach((row) => {
            options.add(row.values[id]);
        });
        return [...options.values()];
    }, [id, preFilteredRows]);

    let offsetObj = [0, 0];

    if (id === "department") offsetObj = [76, 18];
  

    let name = id;

    switch(id) {
      case 'department':
        name = 'Department';
        break;
    //   case 'year':
    //     name = 'Select Year';
    //     break;

    //   case 'branch':
    //     name = 'Select Class';
    //     break;

    //   case 'joinBatch':
    //     name = 'Select Session';
    //     break;
    //   case 'trackName':
    //     name = 'Select Track';
    //     break;
    //   case 'gender':
    //     name = 'Select Gender';
    //     break;

      default:
        break;
    }

    return (
        <Fragment>
            <div className="d-flex justify-content-end">
                {/* <span className="block capitalize mb-4">{id}</span> */}
                <CPopover
                    // trigger={['focus','click','hover']}
                    offset={offsetObj}
                    content={
                        <div className="">
                            {options.map((option, i) => {
                                let option_label = option;

                                if (id === "is_active") {
                                    if (option === "true") option_label = "Active";
                                    else option_label = "Deactive";
                                } else if (id === "year") {
                                    if (option === "I") option_label = "I Year";
                                    else if (option === "II") option_label = "II Year";
                                    else if (option === "III") option_label = "III Year";
                                } else if (id === "gender") {
                                    if (option === "female") option_label = "Female";
                                    else if (option === "male") option_label = "Male";
                                }

                                return (
                                    <Fragment key={i}>
                                        <div
                                            id={`${id}`}
                                            style={{ height: "30px", cursor: "pointer" }}
                                            className="filter_btn_hover p-1 pt-2 my-1 d-flex align-items-center "
                                        >
                                            <label
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                }}
                                                className="font-medium text-gray-700 d-flex align-items-center cursor-pointer"
                                                // onCLick={}
                                                style={{ cursor: "pointer" }}
                                            >
                                                <input
                                                    checked={filterValue.includes(option)}
                                                    type="checkbox"
                                                    className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded mr-1"
                                                    id={option}
                                                    name={option}
                                                    value={option}
                                                    style={{ cursor: "pointer" }}
                                                    onChange={(e) => {
                                                        setFilter(
                                                            setFilteredParams(filterValue, e.target.value)
                                                        );
                                                    }}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                    }}
                                                ></input>

                                                {option_label}
                                            </label>
                                        </div>
                                    </Fragment>
                                );
                            })}
                        </div>
                    }
                    placement="right"
                >
                    <div className="btn-group filter_btn_hover">
                        <button
                            onClick={(e) => {
                                // document.getElementsByClassName('filter_btn').forEach(ele => ele.click())
                            }}
                            className="btn filter_btn"
                        >
                            {name}
                        </button>
                        <img
                            src={rightArrow}
                            alt=">"
                            width="6px"
                            style={{
                                marginTop: "4px",
                                marginRight: "10px",
                            }}
                        />
                    </div>
                </CPopover>
            </div>
        </Fragment>
    );
}

const AttedanceClassStudents = ({ date, setIsLoading }) => {
    const [attedance, setAttendance] = useState([]);
    const [isToday, setIsToday] = useState(false);
    useEffect(() => {
        const token = localStorage.getItem("token");
        const getData = async () => {
            var con = {
                method: "GET",
                url: allUrls.getEmployeesAttandance,
                params: {
                    dateFrom: `${date.a.getFullYear()}-${(parseInt(date.a.getMonth()) >= 9) ? "" : "0"}${date.a.getMonth() + 1}-${(parseInt(date.a.getDate()) >= 9) ? "": "0" }${date.a.getDate()}`,
                    dateTo: `${date.b.getFullYear()}-${(parseInt(date.b.getMonth()) >= 9) ? "" : "0"}${date.b.getMonth() + 1}-${(parseInt(date.b.getDate()) >= 9) ? "": "0" }${date.b.getDate()}`
                },
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            };

            /////////////////////////
            setIsLoading(true)
            const att = await axios(con)
            // console.log(att);
            if (att.status === 200) {
                setAttendance(att.data.sort((a, b) => (parseInt(b.percent)) - (parseInt(a.percent))))
                setIsLoading(false);
                if(con.params.dateFrom === con.params.dateTo){
                    setIsToday(true)
                }else{
                      setIsToday(false)
                }
            }

        }
        getData();

    }, [date,setIsLoading]);

 
    function getColor(value) {
        //value from 0 to 1
        value = 1 - (parseInt(value) / 100);
        // value = 0
        // console.log(value);
        var hue = ((1 - value) * 132).toString(10);
        return ["hsl(", hue, ",59.9%,85%)"].join("");
    }
    function getColorText(value) {
        //value from 0 to 1
        value = 1 - (parseInt(value) / 100);
        // value = 0
        // console.log(value);
        var hue = ((1 - value) * 132).toString(10);
        return ["hsl(", hue, ",100%,22.9%)"].join("");
    }




    const columns = React.useMemo(
        () => [
            {
                header: "S.No",
                accessor: "Srno",
                Cell: ({ row: { original, index } }) => {
                    return index + 1;
                },
                Filter: "",
                filter: "",
            },
            {
                header: "Name",
                accessor: "name",
                Filter: "",
                filter: "",
            },
            {
                header: "Department",
                accessor: "department",
                Filter: SelectColumnFilter,
                filter: MultipleFilter,
            },
            {
                header: "Designation",
                accessor: "designation",
                Filter: "",
                filter: "",
            },
            {
                header: "Mobile",
                accessor: "mobile",
                Filter: "",
                filter: "",
            },
            {
                header: "percent",
                accessor: "percent",
                Cell: ({ row: { original } }) => (
                    <div className='p-2 fw-bold d-flex justify-content-center' style={{ borderRadius: "10px", backgroundColor: getColor(original.percent), color: getColorText(original.percent) }} >
                        {original.percent}
                    </div>),
                Filter: "",
                filter: "",
            }

        ],
        []
    );
    const columns2 = React.useMemo(
        () => [
            {
                header: "S.No",
                accessor: "Srno",
                Cell: ({ row: { original, index } }) => {
                    return index + 1;
                },
                Filter: "",
                filter: "",
            },
            {
                header: "Name",
                accessor: "name",
                Filter: "",
                filter: "",
            },
            {
                header: "Department",
                accessor: "department",
                Filter: SelectColumnFilter,
                filter: MultipleFilter,
            },
            {
                header: "Designation",
                accessor: "designation",
                Filter: "",
                filter: "",
            },
            {
                header: "Mobile",
                accessor: "mobile",
                Filter: "",
                filter: "",
            },
            {
                header: "Log Time",
                accessor: "totalTime",
                Filter: "",
                filter: "",
            },
            {
                header: "In Time",
                accessor: "first_in_time",
                Filter: "",
                filter: "",
            },
            {
                header: "Out Time",
                accessor: "last_out_time",
                Filter: "",
                filter: "",
            },
            {
                header: "percent",
                accessor: "percent",
                Cell: ({ row: { original } }) => (
                    <div className='p-2 fw-bold d-flex justify-content-center' style={{ borderRadius: "10px", backgroundColor: getColor(original.percent), color: getColorText(original.percent) }} >
                        {original.percent}
                    </div>),
                Filter: "",
                filter: "",
            }

        ],
        []
    );



    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        rows,
        nextPage,
        previousPage,
        canPreviousPage,
        pageCount,
        gotoPage,
        canNextPage,
        setPageSize,
        pageOptions,
        state,
        setGlobalFilter,
        preGlobalFilteredRows,
        selectedFlatRows,
        prepareRow,
    } = useTable(
        { columns:(isToday ? columns2 : columns), data: attedance },
        useGlobalFilter,
        useFilters,
        useSortBy,
        usePagination,
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => {
                return [
                    {
                        id: "selection",
                        header: ({ getToggleAllRowsSelectedProps }) => (
                            <TableCheckbox {...getToggleAllRowsSelectedProps()} />
                        ),
                        Cell: ({ row }) => (
                            <TableCheckbox {...row.getToggleRowSelectedProps()} />
                        ),
                    },
                    ...columns,
                ];
            });
        }
    );

    const { globalFilter, pageSize, pageIndex } = state;

    var idData = [];
    var exportCsv = [];

    const checkboxData = JSON.stringify(
        {
            selectedFlatRows: selectedFlatRows.forEach((row) => {
                let data = Object.assign({}, row.original);
                // console.log("data", data);
                delete data.photo;

                idData.push(data.id);
                // // console.log(selectedData);
                exportCsv.push(data);
            }),
        },
        null,
        2
    );


    // console.log(checkboxData.length);
    return false ? (
        <SkeletonColor></SkeletonColor>
    ) : (
        //  table_data.error ? (
        //   <h2>{table_data.error}</h2>
        // ) :
        <Fragment>
            {/* {attedance && <Loader />} */}
            <ToastContainer
                position="top-center"
                autoClose={2500}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
      
            {/* {attedance && <Loader />} */}
            <div className="container-fluid">
                <div
                    style={{
                        position: "sticky",
                        top: "80px",
                        backgroundColor: "#f4f7fc",
                        zIndex: "6",
                        paddingBottom: "20px",
                    }}
                >
                    <div className="row  mx-0 mt-3">
                        <div className="d-flex">
                            <div style={{ marginLeft: "-12px" }}>
                                <select
                                    className="form-select table_select_row_options"
                                    value={pageSize}
                                    onChange={(e) => setPageSize(Number(e.target.value))}
                                >
                                    {[10, 25, 50, 100].map((pageSize) => (
                                        <option value={pageSize} key={pageSize}>
                                            Show Entries {pageSize}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="ms-4">
                                <div className="btn-group  ml-3">
                                    <button
                                        className="btn  btn-sm download-btn dropdown-toggle"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        Download
                                    </button>
                                    <div className="dropdown-menu mt-1" style={{ zIndex: "10" }}>
                                        <div>
                                            <div>
                                                <CSVLink
                                                    className="dropdown-item"
                                                    style={{ fontWeight: "bold" }}
                                                    data={exportCsv}
                                                >
                                                    Excel
                                                </CSVLink>
                                            </div>{" "}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex ml-auto me-3">
                                <div
                                    className="d-flex mr-2"
                                    style={{
                                        height: "40px",
                                        width: "42px",
                                        backgroundColor: "#fff",
                                        borderRadius: "3px",
                                        border: "1px solid #EDEDED",
                                    }}
                                >
                                    <CDropdown variant="nav-item" style={{ color: "white" }}>
                                        <CDropdownToggle
                                            placement="bottom-end"
                                            className="py-0"
                                            caret={false}
                                        >
                                            <img
                                                src={filtericon}
                                                alt=""
                                                style={{
                                                    height: "22px",
                                                    width: "35px",
                                                    marginTop: "-35px",
                                                    marginLeft: "-13px",
                                                }}
                                            />
                                        </CDropdownToggle>

                                        <CDropdownMenu
                                            component={"div"}
                                            className="pt-0 filter-dropdown-menu"
                                            placement="bottom-end"
                                        >
                                            <div className="p-lg-2 pb-0">
                                                {headerGroups.map((headerGroup) => (
                                                    <div
                                                        style={{ display: "flex flex-column" }}
                                                        {...headerGroup.getHeaderGroupProps()}
                                                    >
                                                        {headerGroup.headers.map((column, i) => (
                                                            <div
                                                                key={i}
                                                                style={{
                                                                    display: "block",
                                                                    justifyContent: "center",
                                                                }}
                                                            >
                                                                {column.canFilter
                                                                    ? column.render("Filter")
                                                                    : null}
                                                            </div>
                                                        ))}
                                                    </div>
                                                ))}
                                            </div>
                                        </CDropdownMenu>
                                    </CDropdown>
                                </div>
                                <GlobalFilter
                                    preGlobalFilteredRows={preGlobalFilteredRows}
                                    filter={globalFilter}
                                    setFilter={setGlobalFilter}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <table {...getTableProps()} id="customers">
                    <thead
                        style={{
                            position: "sticky",
                            top: "135px",
                            width: "100%",
                            backgroundColor: "#f4f7fc",
                            zIndex: "5",
                        }}
                    >
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        {column.render("header")}
                                        <span>
                                            {column.isSorted ? (
                                                column.isSortedDesc ? (
                                                    <img
                                                        src={updown_sort}
                                                        style={{ marginLeft: "5px" }}
                                                        alt=""
                                                    />
                                                ) : (
                                                    <img
                                                        src={updown_sort}
                                                        style={{ marginLeft: "5px" }}
                                                        alt=""
                                                    />
                                                )
                                            ) : (
                                                ""
                                            )}
                                            {column.isSorted ? (column.isSortedDesc ? "" : "") : ""}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <NoDataFound rows={rows} />

                <Pagination
                    data={rows}
                    rows={rows}
                    page={page}
                    pageIndex={pageIndex}
                    pageCount={pageCount}
                    pageSize={pageSize}
                    canPreviousPage={canPreviousPage}
                    previousPage={previousPage}
                    pageOptions={pageOptions}
                    gotoPage={gotoPage}
                    canNextPage={canNextPage}
                    nextPage={nextPage}
                />
            </div>
        </Fragment>
    );

}


export default AttedanceClassStudents;