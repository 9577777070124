import { useEffect, useState } from "react";
import AllUrl from "../../../../redux/constants/url";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import paymentSucess from "../../../assests/image/payment_sucess.png";
import paymentFailed from "../../../assests/image/payment_failed.png";
import { toast, ToastContainer } from "react-toastify";
import { decryptWithAES } from "../../../../helpers/Crypto";
import Loader from "../../../assests/common/Loader";

const FeesPaymentSuccessPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [paymentStatus, setPaymentStatus] = useState();
  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const merchantTransactionId = new URLSearchParams(location.search).get(
    "merchantTransactionId"
  );
  const token = localStorage.getItem("token");

  useEffect(() => {
    let fetchData = async () => {
      setLoading(true);
      var config = {
        method: "GET",
        url: `${AllUrl?.checkOnlineFeeStatus}?merchantTransactionId=${merchantTransactionId}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      var result = await axios(config);
      if (result?.status === 200) {
        setPaymentStatus(result?.data?.receiptS3Url);
        toast.success("Receipt successfully sent to your WhatsApp", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const downloadFeesReceipt = async () => {
    setDownloadLoading(true);
    // Fetch the file from the server
    const response = await fetch(paymentStatus, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
      },
    });

    if (response.ok) {
      const blob = await response?.blob();
      const url = window.URL.createObjectURL(blob);

      // Create a temporary anchor tag for downloading the file
      const link = document.createElement("a");
      link.href = url;
      link.download = "PaymentReceipt.pdf"; // The filename for the downloaded file
      link.click(); // Trigger download

      // Cleanup: Revoke the object URL after download
      window.URL.revokeObjectURL(url);
      setTimeout(() => {
        setDownloadLoading(false);
        let verifiedOTPData = decryptWithAES(
          localStorage.getItem("verifiedOTP")
        );
        const extractedAadhar = verifiedOTPData.split("_")[1]; // Gets the part after '_'
        navigate(`/fee-details/${extractedAadhar}`);
      }, 7000);
    } else {
      toast.error("Failed to download receipt, Try Again!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2500}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="container-fluid d-flex justify-content-center align-items-center vh-100 vw-100">
        {!loading ? (
          <div className="d-flex flex-column justify-content-center align-items-center">
            {paymentStatus !== "FAILED" ? (
              <>
                {" "}
                <img
                  src={paymentSucess}
                  alt=""
                  height={"400px"}
                  width={"400px"}
                />
                <h1>Payment Success</h1>
                <button
                  className="btn"
                  disabled={downloadLoading}
                  style={{
                    backgroundColor: "#f9a826",
                    color: "#fff",
                    padding: "10px 20px",
                    borderRadius: "5px",
                    border: "none",
                    cursor: "pointer",
                    marginTop: "20px",
                  }}
                  onClick={downloadFeesReceipt}
                >
                  Download Receipt
                </button>
              </>
            ) : (
              <>
                {" "}
                <img
                  src={paymentFailed}
                  alt=""
                  height={"400px"}
                  width={"400px"}
                />
                <h1>Payment Failed!</h1>
                <button
                  className="btn"
                  style={{
                    backgroundColor: "#f9a826",
                    color: "#fff",
                    padding: "10px 20px",
                    borderRadius: "5px",
                    border: "none",
                    cursor: "pointer",
                    marginTop: "20px",
                  }}
                  onClick={() => navigate("/online-fee-payment")}
                >
                  Try Again
                </button>
              </>
            )}
          </div>
        ) : (
          <Loader fullPage={true} />
        )}
      </div>
    </>
  );
};

export default FeesPaymentSuccessPage;
