import "./OnlineFeepayment.css";
const OnlinePageFooter = () => {
  return (
    <>
      <footer className="main-footer">
        <div className="footer-wrapper section-padding pb-2">
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div
                className="wow zoomIn col-xs-12 col-sm-6 col-md-12 animated d-flex justify-content-center"
                style={{ visibility: "visible", animationName: "zoomIn" }}
              >
                <ul className="footercontact">
                  <li>
                    <i className="flaticon-mainpage" />
                    <span className="li-span">address:</span> NH
                    59-A,Gram-Sandalpur, Tehsil Khategaon, District Dewas, MP,
                    India, Pin Code – 455336{" "}
                  </li>
                  <li>
                    <i className="flaticon-mainpage" />
                    <span className="li-span">Contact:</span> For Any issues
                    contact with Us :{" "}
                    <span
                      className="li-span"
                      style={{ textTransform: "lowercase" }}
                    >
                      {" "}
                      <span
                        style={{
                          textDecoration: "uppercase",
                          color: "#e84700",
                        }}
                      >
                        Email:-
                      </span>{" "}
                      fees@ssism.org{" "}
                      <span
                        style={{
                          textDecoration: "uppercase",
                          color: "#e84700",
                        }}
                      >
                        {" "}
                      </span>{" "}
                      <span
                        style={{
                          textDecoration: "uppercase",
                          color: "#e84700",
                        }}
                      >
                        Phone:-
                      </span>{" "}
                      +919589725372{" "}
                    </span>
                  </li>
                </ul>
                <i className="flaticon-home78" />
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom d-flex justify-content-center align-items-center">
          <div className="container d-flex justify-content-center align-items-center">
            <div className="row">
              <div
                className="wow zoomIn col-xs-12 animated d-flex text-center align-items-center"
                style={{ visibility: "visible", animationName: "zoomIn" }}
              >
                <p className="payment_p_tag">
                  © 2024 All rights reserved. <span>SSISM</span> by{" "}
                  <a href="https://ssism.org/index.html">
                    Singaji Software Solutions
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default OnlinePageFooter;
