import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  ClickAwayListener,
  Popper,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { Fragment, useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import AllUrl from "../../../../redux/constants/url";
import LoaderButton from "../../../assests/common/LoaderButton";
import Icon_feather_download from "../../../assests/image/AccountIcons/receipt_download_icon_white.svg";
import Icon_feather_download_black from "../../../assests/image/AccountIcons/receipt_download_icon.svg";
import { CModal, CModalBody } from "@coreui/react";
import crossButton from "../../../assests/image/crossButton.svg";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const FeesDetailsAccordion = ({ StudentProfileData, receiptData, i }) => {
  const [expanded, setExpanded] = useState(i === 0);
  const [loadingForInitiate, setLoadingForInitiate] = useState(false);
  const [visible, setVisible] = useState(false);
  const [payingFullFee, setPayingFullFee] = useState(false);
  const [totalWaiveOffState, setTotalWaiveOff] = useState(0);
  const [isWaiveOff, setIsWaiveOff] = useState({
    1: false,
    2: false,
    3: false,
    POSTMETRIC: false,
    GKB: false,
  });
  const [waiveOffTotalAmount, setWaiveOffTotalAmount] = useState({
    1: 0,
    2: 0,
    3: 0,
    POSTMETRIC: 0,
    GKB: 0,
  });
  const [amountForPay, setAmountForPay] = useState({
    amount: 0,
    isFullPaid: false,
    title: "",
    1: 0,
    2: 0,
    3: 0,
    POSTMETRIC: 0,
    GKB: 0,
  });
  const [initialAmountForPay] = useState({
    1: StudentProfileData?.firstInstallment,
    2: StudentProfileData?.secondInstallment,
    3: StudentProfileData?.thirdInstallment,
    POSTMETRIC: StudentProfileData?.postmetricAmount,
    GKB: StudentProfileData?.GKBAmount,
  });

  const [installmentData, setInstallmentData] = useState({
    1: 0,
    2: 0,
    3: 0,
    POSTMETRIC: 0,
    GKB: 0,
    WAIVEOFF: 0,
  });
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  const language = localStorage.getItem("language") || "English";
  const [firstRenderForcardWaiveOff, setFirstRenderForcardWaiveOff] =
    useState(false);
  useEffect(() => {
    let totalWaiveOff = 0;
    receiptData?.forEach((item) => {
      if (
        item?.installmentNo === "WAIVEOFF" &&
        item?.year === StudentProfileData?.year &&
        item?.revisionNumber === StudentProfileData?.revisionNumber
      ) {
        totalWaiveOff = totalWaiveOff + item?.waiveOf;
      }
    });
    setTotalWaiveOff(totalWaiveOff);
  }, [receiptData]);

  const getInstTotalPaid = (instNo) => {
    if (StudentProfileData && receiptData) {
      let firstInstTotal = 0;
      let secondInstTotal = 0;
      let thirdInstTotal = 0;
      let scholarshipTotal = 0;
      let gkbTotal = 0;

      let firstInstWaiveOffTotal = 0;
      let secondInstWaiveOffTotal = 0;
      let thirdInstWaiveOffTotal = 0;
      let scholarshipWaiveOffTotal = 0;
      let gkbWaiveOffTotal = 0;

      let totalWaiveOffGetInstTotal = 0;
      receiptData?.forEach((item) => {
        if (
          item?.installmentNo === "WAIVEOFF" &&
          item?.year === StudentProfileData?.year &&
          item?.revisionNumber === StudentProfileData?.revisionNumber
        ) {
          totalWaiveOffGetInstTotal = totalWaiveOffGetInstTotal + item?.waiveOf;
        }
      });

      receiptData?.forEach((receipt) => {
        if (
          receipt.installmentNo === "1" &&
          StudentProfileData?.year === receipt?.year &&
          StudentProfileData?.revisionNumber === receipt?.revisionNumber
        ) {
          firstInstWaiveOffTotal += Number(receipt?.waiveOf);
          firstInstTotal += Number(receipt.receivedAmount);
        } else if (
          receipt.installmentNo === "2" &&
          StudentProfileData?.year === receipt?.year &&
          StudentProfileData?.revisionNumber === receipt?.revisionNumber
        ) {
          secondInstWaiveOffTotal += Number(receipt?.waiveOf);
          secondInstTotal += Number(receipt.receivedAmount);
        } else if (
          receipt.installmentNo === "3" &&
          StudentProfileData?.year === receipt?.year &&
          StudentProfileData?.revisionNumber === receipt?.revisionNumber
        ) {
          thirdInstWaiveOffTotal += Number(receipt?.waiveOf);
          thirdInstTotal += Number(receipt.receivedAmount);
        } else if (
          receipt.installmentNo === "POSTMETRIC" &&
          StudentProfileData?.year === receipt?.year &&
          StudentProfileData?.revisionNumber === receipt?.revisionNumber
        ) {
          scholarshipWaiveOffTotal += Number(receipt?.waiveOf);
          scholarshipTotal += Number(receipt.receivedAmount);
        } else if (
          receipt.installmentNo === "GKB" &&
          StudentProfileData?.year === receipt?.year &&
          StudentProfileData?.revisionNumber === receipt?.revisionNumber
        ) {
          gkbWaiveOffTotal += Number(receipt?.waiveOf);
          gkbTotal += Number(receipt.receivedAmount);
        }
      });

      setIsWaiveOff((prev) => ({
        ...prev,
        1:
          firstInstWaiveOffTotal > 0 || totalWaiveOffGetInstTotal > 0
            ? true
            : false,
        2: secondInstWaiveOffTotal > 0 ? true : false,
        3: thirdInstWaiveOffTotal > 0 ? true : false,
        POSTMETRIC: scholarshipWaiveOffTotal > 0 ? true : false,
        GKB: gkbWaiveOffTotal > 0 ? true : false,
      }));
      setWaiveOffTotalAmount((prev) => ({
        ...prev,
        1: firstInstWaiveOffTotal,
        2: secondInstWaiveOffTotal,
        3: thirdInstWaiveOffTotal,
        POSTMETRIC: scholarshipWaiveOffTotal,
        GKB: gkbWaiveOffTotal,
      }));

      if (instNo === "1") {
        return (
          firstInstTotal + firstInstWaiveOffTotal + totalWaiveOffGetInstTotal
        );
      } else if (instNo === "2") {
        return secondInstTotal + secondInstWaiveOffTotal;
      } else if (instNo === "3") {
        return thirdInstTotal + thirdInstWaiveOffTotal;
      } else if (instNo === "POSTMETRIC") {
        return scholarshipTotal + scholarshipWaiveOffTotal;
      } else if (instNo === "GKB") {
        return gkbTotal + gkbWaiveOffTotal;
      } else return 0;
    }
  };

  const [firstRender, setFirstRender] = useState(false);
  useEffect(() => {
    if (!firstRender) {
      setInstallmentData({
        1: getInstTotalPaid("1"),
        2: getInstTotalPaid("2"),
        3: getInstTotalPaid("3"),
        POSTMETRIC: getInstTotalPaid("POSTMETRIC"),
        GKB: getInstTotalPaid("GKB"),
      });
    } else setFirstRender(true);
  }, [StudentProfileData]);

  useEffect(() => {
    const calculateCardData = () => {
      let isGKB = StudentProfileData?.GKBAmount > 0;
      let isPostmetric = StudentProfileData?.postmetricAmount > 0;
      let totalAmount = StudentProfileData?.totalFee;
      let gkbAmount = isGKB ? StudentProfileData?.GKBAmount : 0;
      let postmetricAmount = isPostmetric
        ? StudentProfileData?.postmetricAmount
        : 0;
      let waiveAmountInCardData = 0;
      if (!firstRenderForcardWaiveOff) {
        receiptData?.forEach((item) => {
          if (
            item?.installmentNo === "WAIVEOFF" &&
            item?.year === StudentProfileData?.year &&
            StudentProfileData?.revisionNumber === item?.revisionNumber
          ) {
            waiveAmountInCardData = waiveAmountInCardData + item?.waiveOf;
          }
        });
        setFirstRenderForcardWaiveOff(true);
      } else setFirstRenderForcardWaiveOff(true);

      // Total paid in the first 3 installments
      let paidFirstInstallment = installmentData["1"] + waiveAmountInCardData;
      let paidSecondInstallment = installmentData["2"];
      let paidThirdInstallment = installmentData["3"];

      // Remaining amounts for the first 3 installments
      let remainingFirstInstallment =
        StudentProfileData?.firstInstallment - paidFirstInstallment;
      let remainingSecondInstallment =
        StudentProfileData?.secondInstallment - paidSecondInstallment;
      let remainingThirdInstallment =
        StudentProfileData?.thirdInstallment - paidThirdInstallment;
      let remainingPostmetricInstallment = isPostmetric
        ? postmetricAmount - installmentData["POSTMETRIC"]
        : 0;
      let remainingGKBInstallment = isGKB
        ? gkbAmount - installmentData["GKB"]
        : 0;

      // Adjust amounts within the first 3 installments only
      const adjustInstallments = () => {
        // Adjusting the first installment
        if (remainingFirstInstallment < 0) {
          let extraPaid = Math.abs(remainingFirstInstallment); // Overpayment in 1st
          remainingFirstInstallment = 0;
          remainingSecondInstallment -= extraPaid; // Apply overpayment to 2nd
        }

        // Adjusting the second installment
        if (remainingSecondInstallment < 0) {
          let extraPaid = Math.abs(remainingSecondInstallment); // Overpayment in 2nd
          remainingSecondInstallment = 0;
          remainingThirdInstallment -= extraPaid; // Apply overpayment to 3rd
        }

        // Adjusting the third installment
        if (remainingThirdInstallment < 0) {
          let extraPaid = Math.abs(remainingThirdInstallment); // Overpayment in 3rd
          remainingThirdInstallment = 0;
          if (isPostmetric) {
            remainingPostmetricInstallment -= extraPaid;
          } else if (isGKB) {
            remainingGKBInstallment -= extraPaid;
          }
        }
        if (remainingPostmetricInstallment < 0 && isGKB) {
          let extraPaid = Math.abs(remainingPostmetricInstallment);
          remainingPostmetricInstallment = 0;
          remainingGKBInstallment -= extraPaid;
        }
        if (remainingGKBInstallment < 0) {
          let extraPaid = Math.abs(remainingGKBInstallment);
          remainingGKBInstallment -= extraPaid;
        }
      };

      // Perform the installment adjustments
      adjustInstallments();
      // Check if total paid amount exceeds the total fee
      let totalPaid =
        paidFirstInstallment +
        paidSecondInstallment +
        paidThirdInstallment +
        (isPostmetric ? installmentData["POSTMETRIC"] : 0) +
        (isGKB ? installmentData["GKB"] : 0);

      let newYear = StudentProfileData?.year;
      switch (StudentProfileData?.year) {
        case "I":
          if (language === "Hindi") {
            newYear = "प्रथम वर्ष";
          } else {
            newYear = 1;
          }
          break;
        case "II":
          if (language === "Hindi") {
            newYear = "द्वितीय वर्ष";
          } else {
            newYear = 2;
          }
          break;
        case "III":
          if (language === "Hindi") {
            newYear = "तृतीय वर्ष";
          } else {
            newYear = 3;
          }
          break;
        default:
          break;
      }

      // Check if the total paid amount is greater than or equal to the total fee
      if (totalPaid >= totalAmount) {
        setAmountForPay((prev) => ({
          ...prev,
          totalPaid: totalPaid,
          installmentNo: "",
          amount: totalPaid,
          isFullPaid: true,
          title:
            language === "English"
              ? `All installments have been paid for year: ${newYear}`
              : `${newYear} के लिए सभी किश्तें चुका दी गई हैं।`,
        }));
        return;
      }

      // Update the current active installment based on remaining amounts
      const updateInstallmentStatus = () => {
        let totalPaid =
          paidFirstInstallment +
          paidSecondInstallment +
          paidThirdInstallment +
          (isPostmetric ? installmentData["POSTMETRIC"] : 0) +
          (isGKB ? installmentData["GKB"] : 0);
        // Check each installment and return the current one with remaining amount
        if (
          (remainingFirstInstallment > 0 ||
            remainingSecondInstallment > 0 ||
            remainingThirdInstallment > 0 ||
            remainingPostmetricInstallment > 0 ||
            remainingGKBInstallment > 0) &&
          totalPaid < totalAmount
        ) {
          setAmountForPay((prev) => ({
            ...prev,
            1: remainingFirstInstallment,
            2: remainingSecondInstallment,
            3: remainingThirdInstallment,
            POSTMETRIC: remainingPostmetricInstallment,
            GKB: remainingGKBInstallment,
          }));
        }

        if (remainingFirstInstallment > 0) {
          setAmountForPay((prev) => ({
            ...prev,
            installmentNo: "1",
            amount: remainingFirstInstallment,
            isFullPaid: false,
            title: language === "English" ? "First Installment" : "पहली किश्त",
          }));
          return;
        }
        if (remainingSecondInstallment > 0) {
          setAmountForPay((prev) => ({
            ...prev,
            installmentNo: "2",
            amount: remainingSecondInstallment,
            isFullPaid: false,
            title:
              language === "English" ? "Second Installment" : "दूसरी किश्त",
          }));
          return;
        }
        if (remainingThirdInstallment > 0) {
          setAmountForPay((prev) => ({
            ...prev,
            installmentNo: "3",
            amount: remainingThirdInstallment,
            isFullPaid: false,
            title: language === "English" ? "Third Installment" : "तीसरी किश्त",
          }));
          return;
        }
        if (isPostmetric && remainingPostmetricInstallment > 0) {
          setAmountForPay((prev) => ({
            ...prev,
            installmentNo: "POSTMETRIC",
            amount: remainingPostmetricInstallment,
            isFullPaid: false,
            title:
              language === "English"
                ? "Postmetric Installment"
                : "छात्रवृत्ति किश्त",
          }));
          return;
        }
        if (isGKB && remainingGKBInstallment > 0) {
          setAmountForPay((prev) => ({
            ...prev,
            installmentNo: "GKB",
            amount: remainingGKBInstallment,
            isFullPaid: false,
            title:
              language === "English" ? "GKB Installment" : "गाँव की बेटी किश्त",
          }));
          return;
        }

        // If everything is paid
        let fullPaidMessage =
          language === "English"
            ? `All installments have been paid for year: ${newYear}`
            : `${newYear} के लिए सभी किश्तें चुका दी गई हैं।`;
        setAmountForPay((prev) => ({
          ...prev,
          installmentNo: "",
          amount: 0,
          isFullPaid: true,
          title: fullPaidMessage,
        }));
      };

      updateInstallmentStatus();
    };

    calculateCardData();
  }, [StudentProfileData, installmentData, setAmountForPay]);

  const initiatePaymentSend = (payableAmount, instNumber) => {
    function getFormattedDate() {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Add leading zero
      const day = String(currentDate.getDate()).padStart(2, "0"); // Add leading zero
      return `${year}-${month}-${day}`;
    }

    let data = {
      stdId: StudentProfileData?.stdId,
      year: StudentProfileData?.year,
      amount: payableAmount,
      installmentNo: instNumber,
      date: getFormattedDate(),
      accId: StudentProfileData?.id,
    };
    Swal.fire({
      title:
        language === "English" ? "Payment Confirmation" : "भुगतान की पुष्टि",

      html:
        "<hr>" +
        `${
          language === "English"
            ? `Are you sure you want to pay ${payableAmount}?`
            : `क्या आप निश्चित रूप से ${payableAmount} का भुगतान करना चाहते हैं?`
        }`,
      showCancelButton: true,
      cancelButtonText: language === "English" ? "Cancel" : "रद्द करें",
      confirmButtonText: `<h8 style='color:rgb(5, 106, 12);font-size: 18px'>${
        language === "English" ? "Yes" : "हाँ"
      }</h8>`,
      showCloseButton: true,
      cancelButtonColor: "gray",
      confirmButtonColor: "rgb(164, 230, 177)",
      confirmButtonTextColor: "black",
      reverseButtons: true,
      showLoaderOnDeny: true,
      showClass: {
        backdrop: "swal2-noanimation", // disable backdrop animation
        popup: "", // disable popup animation
        icon: "", // disable icon animation
      },
      hideClass: {
        popup: "", // disable popup fade-out animation
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoadingForInitiate(true);
        var config = {
          method: "post",
          url: `${AllUrl.initiateOnlineFee}`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
          data: JSON.stringify(data),
        };
        const result = await axios(config);
        if (result?.status === 200) {
          setLoadingForInitiate(false);
          window.open(result?.data?.paymentUrl, "_blank");
        }
      }
    });
  };

  const rowData = {
    1: {
      title: language === "English" ? "First" : "प्रथम",
      amount: StudentProfileData?.firstInstallment,
      year: StudentProfileData?.year,
    },
    2: {
      title: language === "English" ? "Second" : "द्वितीय",
      amount: StudentProfileData?.secondInstallment,
      year: StudentProfileData?.year,
    },
    3: {
      title: language === "English" ? "Third" : "तृतीय",
      amount: StudentProfileData?.thirdInstallment,
      year: StudentProfileData?.year,
    },
    POSTMETRIC: {
      title: language === "English" ? "Postmetric" : "छात्रवृत्ति",
      amount: StudentProfileData?.postmetricAmount,
      year: StudentProfileData?.year,
    },
    GKB: {
      title: language === "English" ? "GKB" : "गाँव की बेटी",
      amount: StudentProfileData?.GKBAmount,
      year: StudentProfileData?.year,
    },
  };

  const getTotalPendingAmount = () => {
    let totalAmount = 0;
    let totalPaid = 0;
    let totalWaiveOff = 0;
    for (let key in rowData) {
      totalAmount += rowData[key].amount;
      totalPaid += installmentData[key];
    }
    receiptData?.forEach((item) => {
      if (
        item?.installmentNo === "WAIVEOFF" &&
        item?.year === StudentProfileData?.year &&
        StudentProfileData?.revisionNumber === item?.revisionNumber
      ) {
        totalWaiveOff = totalWaiveOff + item?.waiveOf;
      }
    });
    return totalAmount - totalPaid;
  };

  return (
    <>
      <Accordion className="my-2" expanded={expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={() => setExpanded(!expanded)}
          aria-controls="panel2a-content"
          id="panel2a-header"
          style={{
            backgroundColor: "#E6E9F4 ",
            borderBottom: "2px solid orange",
            maxHeight: "50px",
            minHeight: "50px",
          }}
        >
          <Typography
            style={{ color: "#414c97", margin: "0px", fontSize: "14px" }}
          >
            <b>
              {StudentProfileData.year === "I"
                ? language === "English"
                  ? "1st Year"
                  : "प्रथम वर्ष"
                : StudentProfileData.year === "II"
                ? language === "English"
                  ? "2nd Year"
                  : "द्वितीय वर्ष"
                : language === "English"
                ? "3rd Year"
                : "तृतीय वर्ष"}{" "}
              {"( " + StudentProfileData.sessionName + " )"}
              <b style={{ color: "red" }}>
                {" "}
                {parseInt(StudentProfileData.revisionNumber) > 0
                  ? " Attempt " + parseInt(StudentProfileData.revisionNumber)
                  : ""}{" "}
              </b>{" "}
            </b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{ backgroundColor: "#F4F7FC", padding: "15px" }}
        >
          <div
            className="d-flex justify-content-end"
            style={{
              opacity: 10000,
              position: "absolute",
              right: 48,
            }}
          >
            <div
              style={{
                marginTop: "-56px",
                position: "relative",
              }}
            >
              <div>
                <button
                  className="btn btn-outline-warning btn-sm mx-2"
                  style={{
                    backgroundColor: "orange",
                    color: "#FFFFFF",
                    cursor: "pointer",
                  }}
                  onClick={() => setVisible(true)}
                >
                  <img
                    className="mb-1"
                    src={Icon_feather_download}
                    style={{ cursor: "pointer", color: "#FFFFFF" }}
                    alt="_blank"
                  />{" "}
                  {language === "English" ? "Receipt" : "रसीद"}
                </button>
              </div>
            </div>
          </div>
          <div>
            <CModal
              alignment="center"
              visible={visible}
              onClose={() => {
                setVisible(false);
              }}
            >
              <CModalBody>
                <div className="row d-flex w-100 p-0 m-0">
                  <div className="col-12 d-flex justify-content-end align-items-center p-0 m-0">
                    <img
                      onClick={() => {
                        setVisible(!visible);
                      }}
                      style={{
                        height: "25px",
                        marginTop: "-4px",
                        cursor: "pointer",
                        marginBottom: "10px",
                      }}
                      src={crossButton}
                      alt="close"
                    />
                  </div>
                </div>
                <table className="table table-sm">
                  <thead>
                    <tr>
                      <th className="text-center">
                        {language === "English" ? "Inst. No." : "किस्त संख्या"}
                      </th>
                      <th className="text-center">
                        {language === "English" ? "Date" : "तारीख"}
                      </th>
                      <th className="text-center">
                        {language === "English" ? "Amount" : "राशि"}
                      </th>
                      <th className="text-center">
                        {language === "English" ? "Download" : "डाउनलोड"}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {receiptData?.map(
                      (inst) =>
                        inst?.year === StudentProfileData?.year &&
                        StudentProfileData?.revisionNumber ===
                          inst?.revisionNumber && (
                          <tr>
                            <td
                              style={{
                                color: "#656A87",
                                verticalAlign: "middle",
                              }}
                              className="text-center"
                            >
                              {inst?.installmentNo}
                            </td>
                            <td
                              style={{
                                color: "#656A87",
                                verticalAlign: "middle",
                              }}
                              className="text-center"
                            >
                              {inst?.accountsReceiptDate}
                            </td>
                            <td
                              style={{
                                color: "#656A87",
                                verticalAlign: "middle",
                              }}
                              className="text-center"
                            >
                              {inst?.receivedAmount}
                            </td>
                            <td
                              style={{
                                color: "#656A87",
                                verticalAlign: "middle",
                              }}
                              className="text-center"
                            >
                              <a
                                href={inst?.accountsReceiptName}
                                rel="noreferrer"
                                target="_blank"
                              >
                                <img
                                  className="mb-1"
                                  src={Icon_feather_download_black}
                                  style={{ cursor: "pointer" }}
                                  alt="_blank"
                                />
                              </a>
                            </td>
                          </tr>
                        )
                    )}
                  </tbody>
                </table>
              </CModalBody>
            </CModal>

            <div className="row d-flex py-md-3 py-1  m-md-1 justify-content-center p-3">
              <table className="table table-sm">
                <thead>
                  <tr>
                    <th className="text-center">
                      {language === "English" ? "Inst. No." : "किस्त संख्या"}
                    </th>
                    <th className="text-center">
                      {language === "English" ? "Total" : "कुल"}
                    </th>
                    <th className="text-center">
                      {language === "English" ? "Received" : "प्राप्त"}
                    </th>
                    <th className="text-center">
                      {language === "English" ? "Action" : "कार्य"}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(installmentData)?.map((instNo) => (
                    <Fragment key={instNo}>
                      {(installmentData[instNo] > 0 ||
                        rowData[instNo]?.amount > 0) && (
                        <tr>
                          <td
                            style={{
                              color: "#656A87",
                              textAlign: "center",
                              verticalAlign: "middle",
                            }}
                          >
                            {rowData[instNo]?.title}
                          </td>
                          <td
                            style={{
                              color: "#656A87",
                              textAlign: "center",
                              verticalAlign: "middle",
                            }}
                          >
                            {initialAmountForPay[instNo]}
                          </td>
                          <td
                            style={{
                              color: "#656A87",
                              textAlign: "center",
                              verticalAlign: "middle",
                            }}
                          >
                            {installmentData[instNo]}
                            {isWaiveOff[instNo] && (
                              <>
                                <span
                                  className="ml-2"
                                  style={{
                                    cursor: "pointer",
                                  }}
                                >
                                  <ClickAwayListener
                                    onClickAway={handleClickAway}
                                  >
                                    <span>
                                      <InfoOutlinedIcon
                                        sx={{
                                          color: "#2196F3",
                                          cursor: "pointer",
                                          fontSize: 20,
                                        }}
                                        aria-describedby={id}
                                        type="button"
                                        onClick={handleClick}
                                      />
                                      <Popper
                                        id={id}
                                        open={open}
                                        anchorEl={anchorEl}
                                      >
                                        <Box
                                          width={250}
                                          sx={{
                                            border: 1,
                                            p: 1,
                                            bgcolor: "background.paper",
                                          }}
                                        >
                                          {language === "English" && (
                                            <p>
                                              {`You have paid Rs.${
                                                installmentData[instNo] -
                                                waiveOffTotalAmount[instNo] -
                                                totalWaiveOffState
                                              } as ${
                                                rowData[instNo]?.title
                                              } installment and Rs.${
                                                waiveOffTotalAmount[instNo] +
                                                totalWaiveOffState
                                              } as waive-off from the college.`}
                                            </p>
                                          )}
                                          {language === "Hindi" && (
                                            <p>
                                              {`आपने ${
                                                rowData[instNo]?.title
                                              } किस्त के रूप में ₹${
                                                installmentData[instNo] -
                                                waiveOffTotalAmount[instNo] -
                                                totalWaiveOffState
                                              } का भुगतान किया है और कॉलेज द्वारा ₹${
                                                waiveOffTotalAmount[instNo] +
                                                totalWaiveOffState
                                              } माफ किया गया है।`}
                                            </p>
                                          )}
                                        </Box>
                                      </Popper>
                                    </span>
                                  </ClickAwayListener>
                                </span>
                              </>
                            )}
                          </td>
                          {amountForPay?.installmentNo === instNo ? (
                            <td
                              style={{
                                color: "#656A87",
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              {amountForPay[instNo] > 0 &&
                                (!loadingForInitiate ? (
                                  <>
                                    {" "}
                                    <button
                                      className="btn btn-sm mx-2"
                                      disabled={loadingForInitiate}
                                      style={{
                                        backgroundColor: "orange",
                                        color: "#FFFFFF",
                                        cursor: "pointer",
                                        minWidth: "80px",
                                      }}
                                      onClick={() => {
                                        setPayingFullFee(false);
                                        initiatePaymentSend(
                                          amountForPay[instNo],
                                          amountForPay?.installmentNo
                                        );
                                      }}
                                    >
                                      {loadingForInitiate &&
                                      amountForPay?.installmentNo === instNo ? (
                                        <LoaderButton />
                                      ) : (
                                        <>
                                          {language === "English"
                                            ? "Pay"
                                            : "भुगतान करें"}{" "}
                                          {`\u20B9${amountForPay[instNo]}`}
                                        </>
                                      )}
                                    </button>
                                  </>
                                ) : (
                                  <button
                                    className="btn btn-sm mx-2"
                                    disabled={loadingForInitiate}
                                    style={{
                                      backgroundColor: "orange",
                                      color: "#FFFFFF",
                                      cursor: "pointer",
                                      minWidth: "80px",
                                    }}
                                  >
                                    {loadingForInitiate &&
                                    amountForPay?.installmentNo === instNo &&
                                    !payingFullFee ? (
                                      <LoaderButton />
                                    ) : (
                                      <>
                                        {language === "English"
                                          ? "Pay"
                                          : "भुगतान करें"}{" "}
                                        {`\u20B9${amountForPay[instNo]}`}
                                      </>
                                    )}
                                  </button>
                                ))}
                            </td>
                          ) : (
                            <td
                              style={{
                                color: "#656A87",
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              {amountForPay["POSTMETRIC"] > 0 &&
                                instNo === "POSTMETRIC" &&
                                !amountForPay?.isFullPaid &&
                                (!loadingForInitiate ? (
                                  <>
                                    {" "}
                                    <button
                                      className="btn btn-sm mx-2"
                                      disabled={loadingForInitiate}
                                      style={{
                                        backgroundColor: "orange",
                                        color: "#FFFFFF",
                                        cursor: "pointer",
                                        minWidth: "80px",
                                      }}
                                      onClick={() => {
                                        setPayingFullFee(false);
                                        initiatePaymentSend(
                                          amountForPay["POSTMETRIC"],
                                          "POSTMETRIC"
                                        );
                                      }}
                                    >
                                      {loadingForInitiate &&
                                      amountForPay?.installmentNo ===
                                        "POSTMETRIC" ? (
                                        <LoaderButton />
                                      ) : (
                                        <>
                                          {language === "English"
                                            ? "Pay"
                                            : "भुगतान करें"}{" "}
                                          {`\u20B9${amountForPay["POSTMETRIC"]}`}
                                        </>
                                      )}
                                    </button>
                                  </>
                                ) : (
                                  <button
                                    className="btn btn-sm mx-2"
                                    disabled={loadingForInitiate}
                                    style={{
                                      backgroundColor: "orange",
                                      color: "#FFFFFF",
                                      cursor: "pointer",
                                      minWidth: "80px",
                                    }}
                                  >
                                    {loadingForInitiate &&
                                    amountForPay?.installmentNo ===
                                      "POSTMETRIC" &&
                                    !payingFullFee ? (
                                      <LoaderButton />
                                    ) : (
                                      <>
                                        {language === "English"
                                          ? "Pay"
                                          : "भुगतान करें"}{" "}
                                        {`\u20B9${amountForPay["POSTMETRIC"]}`}
                                      </>
                                    )}
                                  </button>
                                ))}
                              {amountForPay["GKB"] > 0 &&
                                instNo === "GKB" &&
                                !amountForPay?.isFullPaid &&
                                (!loadingForInitiate ? (
                                  <>
                                    {" "}
                                    <button
                                      className="btn btn-sm mx-2"
                                      disabled={loadingForInitiate}
                                      style={{
                                        backgroundColor: "orange",
                                        color: "#FFFFFF",
                                        cursor: "pointer",
                                        minWidth: "80px",
                                      }}
                                      onClick={() => {
                                        setPayingFullFee(false);
                                        initiatePaymentSend(
                                          amountForPay["GKB"],
                                          "GKB"
                                        );
                                      }}
                                    >
                                      {loadingForInitiate &&
                                      amountForPay?.installmentNo === "GKB" ? (
                                        <LoaderButton />
                                      ) : (
                                        <>
                                          {language === "English"
                                            ? "Pay"
                                            : "भुगतान करें"}{" "}
                                          {`\u20B9${amountForPay["GKB"]}`}
                                        </>
                                      )}
                                    </button>
                                  </>
                                ) : (
                                  <button
                                    className="btn btn-sm mx-2"
                                    disabled={loadingForInitiate}
                                    style={{
                                      backgroundColor: "orange",
                                      color: "#FFFFFF",
                                      cursor: "pointer",
                                      minWidth: "80px",
                                    }}
                                  >
                                    {loadingForInitiate &&
                                    amountForPay?.installmentNo === "GKB" &&
                                    !payingFullFee ? (
                                      <LoaderButton />
                                    ) : (
                                      <>
                                        {language === "English"
                                          ? "Pay"
                                          : "भुगतान करें"}{" "}
                                        {`\u20B9${amountForPay["GKB"]}`}
                                      </>
                                    )}
                                  </button>
                                ))}
                            </td>
                          )}
                        </tr>
                      )}
                    </Fragment>
                  ))}
                  {totalWaiveOffState > 0 && (
                    <tr>
                      <td
                        style={{
                          borderWidth: "2px",
                          fontWeight: "bold",
                          textAlign: "center",
                          verticalAlign: "middle",
                        }}
                      >
                        {language === "English" ? "Sub Total" : "आंशिक योग"}
                      </td>
                      <td
                        style={{
                          borderWidth: "2px",
                          fontWeight: "bold",
                          textAlign: "center",
                          verticalAlign: "middle",
                        }}
                      >
                        {rowData["1"]?.amount +
                          rowData["2"]?.amount +
                          rowData["3"]?.amount +
                          rowData["POSTMETRIC"]?.amount +
                          rowData["GKB"]?.amount}
                      </td>
                      <td
                        style={{
                          borderWidth: "2px",
                          textAlign: "center",
                          fontWeight: "bold",
                          verticalAlign: "middle",
                        }}
                      >
                        {installmentData["1"] +
                          installmentData["2"] +
                          installmentData["3"] +
                          installmentData["POSTMETRIC"] +
                          installmentData["GKB"] -
                          totalWaiveOffState}
                      </td>
                      <td
                        style={{
                          borderWidth: "2px",
                          textAlign: "center",
                          fontWeight: "bold",
                          verticalAlign: "middle",
                        }}
                      ></td>
                    </tr>
                  )}
                  {totalWaiveOffState > 0 && (
                    <tr>
                      <td
                        style={{
                          color: "#656A87",
                          textAlign: "center",
                          verticalAlign: "middle",
                        }}
                      >
                        {language === "English" ? "Waive OFF" : "छूट"}
                      </td>
                      <td
                        style={{
                          color: "#656A87",
                          textAlign: "center",
                          verticalAlign: "middle",
                        }}
                      >
                        -
                      </td>
                      <td
                        style={{
                          color: "#656A87",
                          textAlign: "center",
                          verticalAlign: "middle",
                        }}
                      >
                        {totalWaiveOffState}
                      </td>
                      <td
                        style={{
                          color: "#656A87",
                          textAlign: "center",
                          verticalAlign: "middle",
                        }}
                      ></td>
                    </tr>
                  )}
                  <tr>
                    <td
                      style={{
                        borderWidth: "2px",
                        fontWeight: "bold",
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      {language === "English" ? "Total" : "कुल"}
                    </td>
                    <td
                      style={{
                        borderWidth: "2px",
                        fontWeight: "bold",
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      {rowData["1"]?.amount +
                        rowData["2"]?.amount +
                        rowData["3"]?.amount +
                        rowData["POSTMETRIC"]?.amount +
                        rowData["GKB"]?.amount}
                    </td>
                    <td
                      style={{
                        borderWidth: "2px",
                        textAlign: "center",
                        fontWeight: "bold",
                        verticalAlign: "middle",
                      }}
                    >
                      {
                        installmentData["1"] +
                          installmentData["2"] +
                          installmentData["3"] +
                          installmentData["POSTMETRIC"] +
                          installmentData["GKB"]
                        // totalWaiveOff
                      }
                    </td>
                    <td
                      style={{
                        borderWidth: "2px",
                        textAlign: "center",
                        fontWeight: "bold",
                        verticalAlign: "middle",
                      }}
                    >
                      {!amountForPay?.isFullPaid ? (
                        <button
                          className="btn btn-sm mx-2"
                          disabled={loadingForInitiate}
                          style={{
                            backgroundColor: "orange",
                            color: "#FFFFFF",
                            cursor: "pointer",
                            minWidth: "80px",
                          }}
                          onClick={() => {
                            setPayingFullFee(true);
                            initiatePaymentSend(
                              getTotalPendingAmount(),
                              amountForPay?.installmentNo
                            );
                          }}
                        >
                          {loadingForInitiate && payingFullFee ? (
                            <LoaderButton />
                          ) : (
                            <>
                              {language === "English" ? "Pay" : "भुगतान करें"}{" "}
                              {`\u20B9${getTotalPendingAmount()}`}
                            </>
                          )}
                        </button>
                      ) : (
                        <button
                          className="btn btn-sm w-auto"
                          disabled
                          style={{
                            backgroundColor: "rgb(25, 135, 84)",
                            color: "#FFFFFF",
                            cursor: "default",
                            minWidth: "80px",
                          }}
                        >
                          {language === "English" ? "Paid" : "भुगतान हैं"}
                        </button>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="row">
                {amountForPay?.isFullPaid !== true && (
                  <ul>
                    {(amountForPay?.installmentNo === "1" ||
                      amountForPay?.installmentNo === "2" ||
                      amountForPay?.installmentNo === "3") &&
                      (language === "English" ? (
                        <li>
                          You can pay Rs. {amountForPay.amount} as your{" "}
                          {amountForPay.title}.
                        </li>
                      ) : (
                        <li>
                          आप अपनी {amountForPay.title} ₹{amountForPay.amount} के
                          रूप में चुका सकते हैं
                        </li>
                      ))}
                    {amountForPay["POSTMETRIC"] > 0 &&
                      (language === "English" ? (
                        <li>
                          You can pay Rs. {amountForPay["POSTMETRIC"]} as your{" "}
                          postmentric installment.
                        </li>
                      ) : (
                        <li>
                          आप अपनी पोस्टमैट्रिक किस्त के रूप में ₹
                          {amountForPay["POSTMETRIC"]} का भुगतान कर सकते हैं।
                        </li>
                      ))}
                    {amountForPay["GKB"] > 0 &&
                      (language === "English" ? (
                        <li>
                          You can pay Rs. {amountForPay["GKB"]} as your GKB
                          installment.
                        </li>
                      ) : (
                        <li>
                          आप अपनी जीकेबी किस्त के रूप में ₹{amountForPay["GKB"]}{" "}
                          का भुगतान कर सकते हैं।
                        </li>
                      ))}
                    {getTotalPendingAmount() > 0 &&
                      (language === "English" ? (
                        <li>
                          You can pay your full pending amount of Rs.{" "}
                          {getTotalPendingAmount()}
                        </li>
                      ) : (
                        <li>
                          आप ₹{getTotalPendingAmount()} की अपनी पूरी बकाया राशि
                          का भुगतान कर सकते हैं।
                        </li>
                      ))}
                  </ul>
                )}
                {amountForPay?.isFullPaid === true && (
                  <ul>
                    <li>{amountForPay?.title}</li>
                  </ul>
                )}
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default FeesDetailsAccordion;
