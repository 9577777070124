import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useAnimate } from "react-simple-animate";

import "../components/styles/adminDashboard.css";

import Dashboard from "../components/dashboard/Dashboard";
import Navbar from "../components/Navbar";
import FeesStructureTable from "../components/FeesStuctureTable";

import Sidebar from "../components/Sidebar";
import StudentProfileDA from "../components/accounts/StudentProfileDA";
import StudentAccountTable from '../components/accounts/StudentAccountTable';
import StudentAccountTableDeactiveStudent from '../components/accounts/StudentAccountTableDeactiveStudent';
// import AddStudent from "../components/AddStudent";
import AddNewStudent from "../components/AddNewStudent";
import { ToastContainer } from "react-toastify";
import SidebarLinks from "./SidebarLinks";
import HeaderLink from "./HeaderLink";
import StudentProfile from "../components/accounts/StudentProfile";
import FeesRecipt from "../components/accounts/FeesRecipt";
import UploadDocumentFrom from '../components/accounts/UploadDocumentFrom'
import UpdatePersonalDetialOfStudent from "../components/accounts/UpdatePersonalDetialOfStudent";
import PendingScholarshipTable from "../components/accounts/PendingScholarshipTable";
import FeesReceiptTable from './../components/accounts/FeesReceiptTable';
import RegisteredStudent from "../components/accounts/RegisteredStudent";
import AluminiAccountTable from "../components/alumini/AluminiAccountTable";
import BusPass from "../components/accounts/busPass";
import StudentActivities from "../components/accounts/StudentActivities";
import ActivityTable from "../components/ActivityTable";
import BusFeesDetailsTable from "../components/accounts/BusFeesDetailsTable";
import BusFeesStudentTable from "../components/accounts/BusFeesStudentTable";
import RegistrationsDashboard from "../components/dashboard/registrations";
import StudentsTableAppliedAndSelfApplied from "../components/StudentsTableAppliedAndSelfApplied";
import DepositAmountTable from "../components/depositAmount/depositAmountTable";
import OnlineFeesListTable from "../components/accounts/OnlineFeesListTable";
function AdminDashboard() {

  // ======= TO OPEN AND COLLAPSE THE SIDEBAR ==========

  const { play, style, isPlaying } = useAnimate({
    start: {
      width: "281px",
      minWidth: "280px",
    },
    end: {
      width: "95px",
      minWidth: "95px",
    },
  });

  return (
    <div className="main_container_dashboard">
      <ToastContainer                         // === TOASTER TO NOTIFY THE ACTIONS ==== (REACT TOASTIFY LIBRARY)
        position="top-center"
        autoClose={2500}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="side_bar_content" style={style}>

        {/* ==================== SIDEBAR ======================== */}
        <Sidebar play={play} isPlaying={isPlaying} Options={SidebarLinks} />
      </div>
      <div className="header_table">
        <div className="header_dashboard">
          <Navbar Options={HeaderLink} />
        </div>
        <div className="table_dashboard">
          <Routes>
            <Route path="" element={<Dashboard />} />
            <Route path="registrations-dashboard" element={<RegistrationsDashboard />} />
            {/* ====================== NESTED ROUTING ====================== */}
            <Route path="studentaccounttable" element={<StudentAccountTable />} />
            <Route path="deactivestudentaccounttable" element={<StudentAccountTableDeactiveStudent />} />
            <Route path="feesreceiptlist" element={<FeesReceiptTable />} />
            <Route path="onlinefeeslist" element={<OnlineFeesListTable />} />

            {/* =============================== NESTED ROUTING================= */}
            <Route path="registeredStudent" element={<RegisteredStudent />} />
            <Route path="selfappliedandapliedstudent/:trackName" element={<StudentsTableAppliedAndSelfApplied />} />
            <Route path="feesstructuretable" element={<FeesStructureTable />} />
            {/* <Route path="addnewstudent" element={<AddStudent />} /> */}
            <Route path="dastudentprofile" element={<StudentProfileDA />} />
            <Route path="dastudentprofile/uploaddocument" element={<UploadDocumentFrom />} />
            <Route path="dastudentprofile/feesrecipt" element={<FeesRecipt />} />
            <Route path="dastudentprofile/updatepersonaldetial" element={<UpdatePersonalDetialOfStudent />} />
            <Route path="studentprofile" element={<StudentProfile />} />
            <Route path="studentprofile/uploaddocument" element={<UploadDocumentFrom />} />
            <Route path="studentprofile/feesrecipt" element={<FeesRecipt />} />
            <Route path="studentprofile/updatepersonaldetial" element={<UpdatePersonalDetialOfStudent />} />
            <Route path="pendingscholarship/:sessionId/:feesType" element={<PendingScholarshipTable />} />
            <Route path="addnewstudent" element={<AddNewStudent />} />
            <Route path="alumini-students" element={<AluminiAccountTable />} />
            {/*  */}
            <Route path="dastudentprofile/bus-pass" element={<BusPass />} />
            <Route path="studentprofile/bus-pass" element={<BusPass />} />
            <Route path="pendingbusfees/:sessionId/:track" element={<BusFeesDetailsTable />} />
            <Route path="pendingbusfeesstudent/:sessionId/:track/:month" element={<BusFeesStudentTable />} />
            {/*  */}
            <Route path="dastudentprofile/activity" element={<StudentActivities />} />
            <Route path="studentprofile/activity" element={<StudentActivities />} />
            <Route path="activitytable" element={<ActivityTable />} />
            <Route path="*" element={<Navigate replace to="/login" />} />
            <Route path="bankdepositamount" element={<DepositAmountTable />} />

          </Routes>
        </div>
      </div>
    </div>
  );
}

export default AdminDashboard;
