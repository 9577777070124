import * as xlsx from 'xlsx'

function findKeyIgnoreCase(obj,keyName) {
    const lowerKeyName = keyName.toLowerCase();
    for(const key in obj) {
        if(key.toLowerCase() === lowerKeyName) {
            return key; // Return the key if found (case-insensitive)
        }
    }
}
export const ExcelDataReader = async (file) => {
    let excelData = [];
    if(file) {

        try {
            if(file) {
                const data = await file.arrayBuffer(file);
                const excelFile = xlsx.read(data);
                const excelSheet = excelFile.Sheets[excelFile.SheetNames[0]];
                let excelJson = xlsx.utils.sheet_to_json(excelSheet);
                if(excelJson.length) {
                    excelJson.forEach((value) => {
                        const keyName = findKeyIgnoreCase(value,'name');
                        if(keyName) {
                            if(keyName.trim() !== "") {
                                excelData.push({
                                    "name": value[keyName],
                                });
                            }
                        }
                        else {
                            if(!keyName && excelData.length) {
                                // alert("Please provide name for all students")
                                excelData = new String("Please provide name for all students");
                                throw new Error("Please provide name for all students");
                            }

                            // alert("file must contain columns for \n1. Email \n2. Name \n3.year \n 4.Branch \n \bNote:- if you have columns please check spelling mistake also.");
                            excelData = new String("file must contain column for  Name  Note:- if you have column please check  spelling mistake  and don't give blank space between rows");

                            throw new Error("file must contain column for  Name  Note:- if you have columns please check  spelling mistake also.");
                        }

                    }
                    );

                } else {
                    excelData = new String("File must Contain some data");
                    throw new Error("Data is not present");
                }
            }
        } catch(err) {
            console.log(err);
        }
        return excelData;
    }
}