export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";

export const NEW_PASS_REQUEST = "NEW_PASS_REQUEST";
export const NEW_PASS_SUCCESS = "NEW_PASS_SUCCESS";
export const NEW_PASS_FAIL = "NEW_PASS_FAIL";

export const FORGETPASSWORD_REQUEST = "FORGETPASSWORD_REQUEST";
export const FORGETPASSWORD_FAIL = "FORGETPASSWORD_FAIL";
export const FORGETPASSWORD_SUCCESS = "FORGETPASSWORD_SUCCESS";

export const ADD_STUDENT_REQUEST = "ADD_STUDENT_REQUEST";
export const ADD_STUDENT_FAIL = "ADD_STUDENT_FAIL";
export const ADD_STUDENT_SUCCESS = "ADD_STUDENT_SUCCESS";

export const CREATE_NEW_ADMIN_REQUEST = "CREATE_NEW_ADMIN_REQUEST";
export const CREATE_NEW_ADMIN_FAIL = "CREATE_NEW_ADMIN_FAIL";
export const CREATE_NEW_ADMIN_SUCCESS = "CREATE_NEW_ADMIN_SUCCESS";

export const CREATE_FEES_STRUCTURE = "CREATE_FEES_STRUCTURE"
export const CREATE_FEES_FAILED = "CREATE_FEES_FAILED"
export const CREATE_FEES_SUCCESS = "CREATE_FEES_SUCCESS"

export const FEES_STRUCTURE_TABLE = "FEES_STRUCTURE_TABLE"
export const FEES_STRUCTURE_TABLE_FAILED = "FEES_STRUCTURE_TABLE_FAILED"
export const FEES_STRUCTURE_TABLE_SUCCESS = "FEES_STRUCTURE_TABLE_SUCCESS"

export const SHOW_SIDE_BAR = "SHOW_SIDE_BAR";
export const HIDE_SIDE_BAR = "HIDE_SIDE_BAR";

export const FETCH_STUDENT_TABLE_DATA = "FETCH_STUDENT_TABLE_DATA"
export const STUDENT_TABLE_DATA_SUCCESS = "STUDENT_TABLE_DATA_SUCCESS"
export const STUDENT_TABLE_DATA_FAIL = "STUDENT_TABLE_DATA_FAIL"
//for second loading constants
export const STUDENT_SECOND_LOADING = "STUDENT_SECOND_LOADING"
export const STUDENT_SECOND_LOADING_END = "STUDENT_SECOND_LOADING_END"


// Student Table Actions
export const REQUEST_STATUS_CHANGE = "REQUEST_STATUS_CHANGE";
export const DEACTIVATE_STUDENT = "DEACTIVATE_STUDENT";
export const ACTIVATE_STUDENT = "ACTIVATE_STUDENT";
export const FAIL_STATUS_REQUEST = "FAIL_STATUS_REQUEST";

// STUDENT VERIFY TABLE DEACTIVE TO ACTIVE ACTION 
export const VERIFY_STUDENT_TABLE_DATA = "VERIFY_STUDENT_TABLE_DATA"
export const VERIFY_STUDENT_TABLE_DATA_SUCCESS = "VERIFY_STUDENT_TABLE_DATA_SUCCESS"
export const VERIFY_STUDENT_TABLE_DATA_FAIL = "VERIFY_STUDENT_TABLE_DATA_FAIL"


export const FETCH_ADMIN_TABLE_DATA = "FETCH_ADMIN_TABLE_DATA"
export const ADMIN_TABLE_DATA_SUCCESS = "ADMIN_TABLE_DATA_SUCCESS"
export const ADMIN_TABLE_DATA_FAIL = "ADMIN_TABLE_DATA_FAIL"
export const FETCH_ADMIN_TABLE_DATA_SEC = "FETCH_ADMIN_TABLE_DATA_SEC"
export const FETCH_ADMIN_TABLE_DATA_SUCCESS = "FETCH_ADMIN_TABLE_DATA_SUCCESS"
export const FETCH_ADMIN_TABLE_DATA_FAIL = "FETCH_ADMIN_TABLE_DATA_FAIL"


export const ADMIN_STATUS_CHANGE_REQUEST = "ADMIN_STATUS_CHANGE_REQUEST"
export const ADMIN_STATUS_CHANGE_SUCCESS = "ADMIN_STATUS_CHANGE_SUCCESS"
export const ADMIN_STATUS_CHANGE_FAIL = "ADMIN_STATUS_CHANGE_FAIL"


export const FETCH_FEES_STRUCT_TABLE_DATA = "FETCH_FEES_STRUCT_TABLE_DATA"
export const FEES_STRUCT_TABLE_DATA_SUCCESS = "FEES_STRUCT_TABLE_DATA_SUCCESS"
export const FEES_STRUCT_TABLE_DATA_FAIL = "FEES_STRUCT_TABLE_DATA_FAIL"

// exporting the fees schema status to active and deactive
export const FEES_STRUCTURE_CHANGE_STATUS = "FEES_STRUCTURE_CHANGE_STATUS"
export const FEES_STRUCTURE_CHANGE_SUCCESS = "FEES_STRUCTURE_CHANGE_SUCCESS"
export const FEES_STRUCTURE_CHANGE_FAIL = "FEES_STRUCTURE_CHANGE_FAIL"

export const CHANGE_TOTAL_FEES = "CHANGE_TOTAL_FEES"
export const CHANGE_TOTAL_FEES_SUCCESS = "CHANGE_TOTAL_FEES_SUCCESS"
export const CHANGE_TOTAL_FEES_FAIL = "CHANGE_TOTAL_FEES_FAIL"

// ------------------------- ACCOUNT TABLE-------------------
export const FETCH_STUDENTACCOUNT_DATA = "FETCH_STUDENTACCOUNT_DATA"
export const STUDENTACCOUNT_TABLE_DATA_FAIL = "STUDENTACCOUNT_TABLE_DATA_FAIL"
export const STUDENTACCOUNT_TABLE_DATA_SUCCESS = "STUDENTACCOUNT_TABLE_DATA_SUCCESS"
// ------------------------- DEACTIVE ACCOUNT TABLE-------------------
export const FETCH_DEACTIVESTUDENTACCOUNT_DATA = "FETCH_DEACTIVESTUDENTACCOUNT_DATA"
export const DEACTIVESTUDENTACCOUNT_TABLE_DATA_FAIL = "DEACTIVESTUDENTACCOUNT_TABLE_DATA_FAIL"
export const DEACTIVESTUDENTACCOUNT_TABLE_DATA_SUCCESS = "DEACTIVESTUDENTACCOUNT_TABLE_DATA_SUCCESS"

export const ACCOUNT_TABLE_ACTION_SUCCESS = "ACCOUNT_TABLE_ACTION_SUCCESS"

export const RECEIPT_GENERATE_REQUEST = "RECEIPT_GENERATE_REQUEST"
export const RECEIPT_GENERATE_SUCCESS = "RECEIPT_GENERATE_SUCCESS"
export const RECEIPT_GENERATE_FAIL = "RECEIPT_GENERATE_FAIL"
// ------------------------- ACCOUNT TABLE eND-------------------


// It the daily report successfully gets data 
export const GET_DAILY_REPORT = "GET_DAILY_REPORT"
export const CHANGE_DAILY_REPORT = "CHANGE_DAILY_REPORT"

export const STUDENTACCOUNT_CHANGE_STATUS = "STUDENTACCOUNT_CHANGE_STATUS"
export const STUDENTACCOUNT_TABLE_CHANGE_SUCCESS = "STUDENTACCOUNT_TABLE_CHANGE_SUCCESS"
export const STUDENTACCOUNT_TABLE_CHANGE_FAIL = "STUDENTACCOUNT_TABLE_CHANGE_FAIL"

export const PENDING_SCHOLARSHIP_TABLE_REQ = "PENDING_SCHOLARSHIP_TABLE_REQ"
export const PENDING_SCHOLARSHIP_TABLE_FAIL = "PENDING_SCHOLARSHIP_TABLE_FAIL"
export const PENDING_SCHOLARSHIP_TABLE_SUCCESS = "PENDING_SCHOLARSHIP_TABLE_SUCCESS"

export const FEES_RECEIPT_TABLE_REQ = "FEES_RECEIPT_TABLE_REQ"
export const FEES_RECEIPT_TABLE_SUCCESS = "FEES_RECEIPT_TABLE_SUCCESS"
export const FEES_RECEIPT_TABLE_FAIL = "FEES_RECEIPT_TABLE_FAIL"

export const ONLINE_FEES_LIST_TABLE_REQ = "ONLINE_FEES_LIST_TABLE_REQ"
export const ONLINE_FEES_LIST_TABLE_SUCCESS = "ONLINE_FEES_LIST_TABLE_SUCCESS"
export const ONLINE_FEES_LIST_TABLE_FAIL = "ONLINE_FEES_LIST_TABLE_FAIL"

export const STUDENTS_TABLE_DATA = "STUDENTS_TABLE_DATA"
export const STUDENTS_TABLE_DATA_SUCCESS = "STUDENTS_TABLE_DATA_SUCCESS"
export const STUDENTS_TABLE_DATA_FAIL = "STUDENTS_TABLE_DATA_FAIL"

export const UPDATE_ACTIVITY_STATUS_REQUEST = "UPDATE_ACTIVITY_STATUS_REQUEST";
export const UPDATE_ACTIVITY_STATUS_FAIL = "UPDATE_ACTIVITY_STATUS_FAIL";
export const UPDATE_ACTIVITY_STATUS_SUCCESS = "UPDATE_ACTIVITY_STATUS_SUCCESS";

// ------------------------- NOTIFICATIONS -------------------

export const SEND_NOTIFICATION_REQUEST = "SEND_NOTIFICATION_REQUEST";
export const SEND_NOTIFICATION_FAIL = "SEND_NOTIFICATION_FAIL";
export const SEND_NOTIFICATION_SUCCESS = "SEND_NOTIFICATION_SUCCESS";

// ------------------------- DEPOSIT AMOUNT ------------------------

export const DEPOSIT_AMOUNT_TABLE_FAIL = "DEPOSIT_AMOUNT_TABLE_FAIL";
export const DEPOSIT_AMOUNT_TABLE_SUCCESS = "DEPOSIT_AMOUNT_TABLE_SUCCESS";
export const DEPOSIT_AMOUNT_TABLE_REQUEST = "DEPOSIT_AMOUNT_TABLE_REQUEST";
