import { useEffect, useState } from "react";
import AllUrl from "../../../../redux/constants/url";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Avatar } from "rsuite";
import AvatarImg from "../../../assests/image/Avtar.jpeg";
import ssism_logo from "../../../assests/image/Singaji_logo.svg";
import FeesDetailsAccordion from "./FeesDetailsAccordion";
import collegeImg from "../../../assests/image/college_img.svg";
import Loader from "../../../assests/common/Loader";
import Swal from "sweetalert2";
import { decryptWithAES } from "../../../../helpers/Crypto";
import OnlinePageFooter from "./OnlinePageFooter";
const StudentFeeDetails = () => {
  const { aadharNo } = useParams();
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const [StudentProfileData, setStudentProfileData] = useState();
  const [newAccountData, setNewAccountData] = useState();
  const [loading, setLoading] = useState(false);
  const [resp, setResp] = useState();
  const [languages, setLanguages] = useState(
    localStorage.getItem("language") || "English"
  );

  useEffect(() => {
    setNewAccountData(
      StudentProfileData?.allAccounts?.reverse()?.map((item) => item)
    );
  }, [StudentProfileData]);

  useEffect(() => {
    let fetchData = async () => {
      setLoading(true);
      let verifiedOTP = localStorage.getItem("verifiedOTP");
      if (!verifiedOTP) {
        navigate("/online-fee-payment");
        return;
      }
      if (verifiedOTP) {
        let verifiedOTPData = decryptWithAES(
          localStorage.getItem("verifiedOTP")
        );
        const extractedAadhar = verifiedOTPData.split("_")[1]; // Gets the part after '_'
        if (aadharNo !== extractedAadhar) {
          localStorage.removeItem("verifiedOTP");
          navigate("/online-fee-payment");
          return;
        }
      }

      var config = {
        method: "POST",
        url: AllUrl?.getStudentFeesDetailsByAadharNo,
        data: {
          aadharNo: aadharNo,
        },
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      try {
        var result = await axios(config);
        if (result?.status === 200) {
          setResp(result?.status);
          setStudentProfileData(result?.data);
          setLoading(false);
        }
      } catch (error) {
        setResp(error?.response?.status);
        if (error?.response?.status === 400) {
          setLoading(false);
          Swal.fire({
            title: "Not Found",
            text: "Please check your aadhar number and try again.",
            icon: "error",
            showCancelButton: true,
            confirmButtonText: "Retry",
            cancelButtonText: "Go Back",
            reverseButtons: true,
            cancelButtonColor: "gray",
            confirmButtonColor: "#4f83df",
            allowOutsideClick: false,
            allowEscapeKey: false,
          }).then((result) => {
            if (result.isConfirmed) {
              // Action for "Retry" button: reload the page
              window.location.reload(); // Reload the current page
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              // Action for "Go Back" button: navigate to a specific URL
              window.location.href = "/online-fee-payment";
            }
          });
        } else {
          setLoading(false);
          Swal.fire({
            title: "Error",
            text: "Internal server error, please try again later.",
            icon: "error",
            showCancelButton: true,
            confirmButtonText: "OK",
            reverseButtons: true,
            cancelButtonColor: "gray",
            confirmButtonColor: "#4f83df",
            allowOutsideClick: false,
            allowEscapeKey: false,
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        }
      }
    };
    fetchData();
  }, []);

  var StudentPhoto = StudentProfileData?.accountInfo?.photo;
  var StudentName =
    StudentProfileData?.accountInfo?.firstName +
    " " +
    StudentProfileData?.accountInfo?.lastName;

  return (
    <>
      {!loading && resp === 200 && (
        <>
          <div
            className="d-flex align-items-center"
            style={{ backgroundColor: "#E6E9F4", borderRadius: "8px" }}
          >
            <div className="col-6 d-flex align-items-center p-1 ms-2">
              <div>
                {StudentPhoto?.includes("/null") ? (
                  <img
                    style={{
                      borderRadius: "50px",
                      height: "70px",
                      width: "70px",
                      marginLeft: "50px",
                      backgroundColor: "#DDDDDD",
                    }}
                    src={AvatarImg}
                    alt="avtar_photo"
                  />
                ) : (
                  <>
                    <div
                      style={{
                        height: "70px",
                        width: "70px",
                        borderRadius: "50%",
                        border: "1px solid black",
                      }}
                    >
                      <img
                        src={
                          StudentPhoto?.includes(
                            `PHOTO_${StudentProfileData?.accountInfo?.stdId}`
                          )
                            ? StudentPhoto
                            : `  ${StudentPhoto?.replace(
                                "documents/",
                                "profile"
                              )?.replace(
                                StudentProfileData?.accountInfo?.stdId,
                                ""
                              )}`
                        }
                        height={"70px"}
                        width={"70px"}
                        style={{
                          borderRadius: "50%",
                          border: "1px solid #DDDDDD",
                        }}
                        alt=""
                      />
                    </div>
                    {/* <Avatar
                      src={
                        StudentPhoto?.includes(
                          `PHOTO_${StudentProfileData?.accountInfo?.stdId}`
                        )
                          ? StudentPhoto
                          : `  ${StudentPhoto?.replace(
                              "documents/",
                              "profile"
                            )?.replace(
                              StudentProfileData?.accountInfo?.stdId,
                              ""
                            )}`
                      }
                      height={100}
                      width={100}
                      alt="profile"
                      style={{
                        borderRadius: "50px",
                        height: "100px",
                        width: "100px",
                        marginLeft: "50px",
                      }}
                      className="mx-auto"
                    >
                      <span
                        style={{ fontSize: "75px", color: "rgb(90, 96, 127)" }}
                      >
                        {StudentName?.slice(0, 1)}
                      </span>
                    </Avatar> */}
                  </>
                )}
              </div>
              <div className="ml-2">
                <div style={{ color: "#5A607F" }}>
                  <span className="fw-bold" style={{ fontSize: "22px" }}>
                    {StudentName}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-6 d-flex justify-content-end">
              <img src={ssism_logo} height={"70px"} alt="rocket" />
            </div>
          </div>
          <div className="col-12 d-flex justify-content-end mb-0 pb-0">
            <div className="">
              <div className="d-flex justify-content-end align-items-center">
                <div style={{ marginLeft: "-12px" }}>
                  <select
                    className="form-select table_select_row_options"
                    value={languages}
                    onChange={(e) => {
                      localStorage.setItem("language", e.target.value);
                      setLanguages(e.target.value);
                    }}
                  >
                    {["Hindi", "English"].map((language) => (
                      <option value={language} key={language}>
                        {language}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="row d-flex container-fluid justify-content-between align-items-center mx-0 px-0">
            <div className="col-6 col-md-6 d-none d-md-block">
              <div
                className="d-flex justify-content-center align-items-center w-100 h-100"
                style={{
                  minHeight: `calc(100vh - 278px)`,
                }}
              >
                <img
                  src={collegeImg}
                  alt="illustration"
                  height={"500px"}
                  width={"500px"}
                />
              </div>
            </div>

            <div className="col-12 col-md-6">
              {newAccountData?.length > 0 &&
                newAccountData?.map((ele, index) => {
                  return (
                    <FeesDetailsAccordion
                      key={index + 1}
                      i={index}
                      StudentProfileData={{
                        ...StudentProfileData?.accountInfo,
                        ...ele,
                      }}
                      receiptData={StudentProfileData?.recieptData}
                    />
                  );
                })}
            </div>
          </div>{" "}
          <OnlinePageFooter />
        </>
      )}
      {loading && <Loader fullPage={true} />}
    </>
  );
};

export default StudentFeeDetails;
