import React, { Fragment, useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { ToastContainer } from "react-toastify";
import { DateRangePicker } from "rsuite";
import SkeletonColor from "../../../helpers/Skeletrone";
import AllUrl from "../../../redux/constants/url";
import Pagination from "../../assests/common/Pagination";
import updown_sort from "../../assests/image/updown_sort.svg";
import "../styles/HeaderDropdown.css";
import "../styles/Table.css";
import { GlobalFilter } from "../tableComponents/GlobalFilter";
// import OfflinePage from "../../auth/OfflinePage";
import fetchDepositAmountListData from "../../../redux/actionDispatcher/superAdmin/dipositAmountTableDataDispatcher";
import NoDataFound from "../../assests/common/NoDataFound";
import dateIcon from "../../assests/image/AccountIcons/DateIcon.svg";
import { TableCheckbox } from "../tableComponents/TableCheckbox";
import { useLocation } from "react-router-dom";

// =============== Table data filteration =========================
const MultipleFilter = (rows, accessor, filterValue) => {
  const arr = [];
  rows.forEach((val) => {
    if (filterValue.includes(val.original[accessor])) arr.push(val);
  });
  return arr;
};

function setFilteredParams(filterArr, val) {
  if (filterArr.includes(val)) {
    filterArr = filterArr.filter((n) => {
      return n !== val;
    });
  } else filterArr.push(val);

  if (filterArr.length === 0) filterArr = undefined;
  return filterArr;
}

// ====================== Column filter =====================
function SelectColumnFilter({
  column: { filterValue = [], setFilter, preFilteredRows, id },
}) {
  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);
}

function DepositAmountTable({ depositAmount, fetchData }) {
  const token = localStorage.getItem("token");

  const [MoneyCount, setMoneyCount] = useState({
    TRamount: 0,
    TDamount: 0,
    Ramount: 0,
  });
  const location = useLocation();

  // Extract query parameters
  const searchParams = new URLSearchParams(location.search);
  const paramDateFrom = searchParams.get("dateFrom");
  const paramDateTo = searchParams.get("dateTo");
  const paramFeeType = searchParams.get("feeType");
  const [feeType, setFeeType] = useState(paramFeeType || "Tution");

  const dt = new Date();
  dt.setDate(1);
  const [date, setDate] = useState({
    a: paramDateFrom ? new Date(paramDateFrom) : dt,
    b: paramDateTo ? new Date(paramDateTo) : new Date(),
  });

  const CIcon = () => {
    return (
      <>
        <img style={{ marginLeft: "-180px" }} src={dateIcon} alt="date" />
      </>
    );
  };

  let dateFrom = `${date.a.getFullYear()}-${(date.a.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${date.a.getDate().toString().padStart(2, "0")}`;
  let dateTo = `${date.b.getFullYear()}-${(date.b.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${date.b.getDate().toString().padStart(2, "0")}`;

  React.useEffect(() => {
    var config = {
      method: "GET",
      url: `${AllUrl.getdepositAmountList}${dateFrom}&${dateTo}`,
      params: {
        feeType: feeType,
      },
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    fetchData(config);
  }, [date, feeType]);

  const columns = React.useMemo(
    () => [
      {
        header: "S.No",
        accessor: "S.N",
        Cell: ({ row: { original, index } }) => {
          return index + 1;
        },
        Filter: "",
        filter: "",
      },
      {
        header: "Date",
        accessor: "date",
        Filter: "",
        filter: "",
      },
      {
        header: "Received Amount",
        accessor: "total_received_amount",
        Filter: "",
        filter: "",
      },
      {
        header: "Deposited Amount",
        accessor: "total_deposit_amount",
        Filter: "",
        filter: "",
      },
      {
        header: "Bank Name",
        accessor: "bankName",
        Filter: SelectColumnFilter,
        filter: MultipleFilter,
        Cell: ({ value }) => (value && value !== "-" ? value : "-"),
      },
      {
        header: "Depositer Name",
        accessor: "remark",
        Filter: "",
        filter: "",
        Cell: ({ value }) => (value && value !== "-" ? value : "-"),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    gotoPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    setPageSize,
    pageOptions,
    selectedFlatRows,
    state,
    pageCount,
    setGlobalFilter,
    rows,
    preGlobalFilteredRows,
    prepareRow,
  } = useTable(
    { columns, data: depositAmount?.table_data },

    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: "selection",
            header: ({ getToggleAllRowsSelectedProps }) => (
              <TableCheckbox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }) => (
              <TableCheckbox {...row.getToggleRowSelectedProps()} />
            ),
          },
          ...columns,
        ];
      });
    }
  );

  const { globalFilter, pageSize, pageIndex } = state;

  var exportCsv = [];
  // eslint-disable-next-line no-unused-vars
  const checkboxData = JSON.stringify(
    {
      selectedFlatRows: selectedFlatRows.forEach((row) => {
        let data = Object.assign({}, row.original);
        if (data?.receivedAmount)
          data.receivedAmount = data?.receivedAmount?.toString();
        exportCsv.push(data);
      }),
    },
    null,
    2
  );
  // console.log(checkboxData)

  React.useEffect(() => {
    let data = rows;
    let Ramount = 0;
    let TRamount = 0;
    let TDamount = 0;
    data.forEach((ele) => {
      ele = ele.original;
      TRamount += ele?.total_received_amount;
      TDamount += ele?.total_deposit_amount;
      Ramount = TRamount - TDamount;
    });

    setMoneyCount((val) => {
      return {
        TRamount,
        Ramount,
        TDamount,
      };
    });
  }, [rows]);

  return depositAmount.loading ? (
    <SkeletonColor></SkeletonColor>
  ) : (
    <Fragment>
      <ToastContainer
        position="top-center"
        autoClose={2500}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="container-fluid">
        <div
          style={{
            position: "sticky",
            top: "80px",
            width: "100%",
            paddingTop: "10px",
            paddingBottom: "10px",
            backgroundColor: "#f4f7fc",
            zIndex: "6",
          }}
        >
          <div
            className="d-flex row Stu-Acc-info "
            style={{
              color: "rgb(90, 96, 127)",
              margin: "Auto",
              height: "70px",
            }}
          >
            <div className="col info-col m-2">
              <h5 style={{ marginTop: "12px" }}>
                {" "}
                {MoneyCount.TRamount
                  ? MoneyCount.TRamount.toLocaleString("en-IN")
                  : "0"}{" "}
                <br /> <p>Total Received Amount</p>{" "}
              </h5>
            </div>
            <div className="col info-col m-2">
              <h5 style={{ marginTop: "12px" }}>
                {MoneyCount.TDamount
                  ? MoneyCount.TDamount.toLocaleString("en-IN")
                  : "-"}{" "}
                <br /> <p>{"Total Deposited Amount"}</p>{" "}
              </h5>
            </div>

            <div className="col info-col m-2">
              <h5 style={{ marginTop: "12px" }}>
                {MoneyCount.Ramount
                  ? MoneyCount.Ramount.toLocaleString("en-IN")
                  : "-"}{" "}
                <br /> <p>{"Remaining Amount"}</p>{" "}
              </h5>
            </div>
          </div>

          <div className="d-flex mt-3 mx-0">
            <div className="d-flex">
              <select
                className="form-select table_select_row_options"
                value={pageSize}
                onChange={(e) => setPageSize(Number(e.target.value))}
              >
                {[10, 25, 50, 100].map((pageSize) => (
                  <option value={pageSize} key={pageSize}>
                    Show Entries {pageSize}
                  </option>
                ))}
              </select>
              <select
                className="form-select table_select_row_options"
                value={feeType}
                onChange={(e) => setFeeType(e.target.value)}
              >
                <option value={"Tution"}>Tution Fees</option>
                <option value={"Bus"}>Bus Fees</option>
              </select>
              <DateRangePicker
                caretAs={CIcon}
                placement="auto"
                value={[date.a, date.b]}
                onClean={(e) => {
                  e.preventDefault();
                }}
                onChange={(value) => {
                  if (!value) {
                    return;
                  }
                  var a = value[0];
                  var b = value[1];
                  setDate({
                    a,
                    b,
                  });
                }}
                appearance="default"
                className="stu-acc-table ms-2"
                placeholder="TO"
                style={{ width: 230 }}
              />
            </div>
            <div className="btn-group  ml-3">
              <button
                className="btn  btn-sm download-btn dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Download
              </button>
              <div className="dropdown-menu mt-1">
                <div>
                  <div>
                    <CSVLink
                      className="dropdown-item"
                      style={{ fontWeight: "bold" }}
                      data={exportCsv}
                    >
                      Excel
                    </CSVLink>
                  </div>{" "}
                </div>
              </div>
            </div>
            <div className="d-flex ml-auto me-1">
              {/* <div
                className="d-flex mr-2"
                style={{
                  height: "40px",
                  width: "42px",
                  backgroundColor: "#fff",
                  borderRadius: "3px",
                  border: "1px solid #EDEDED",
                }}
              >
                
                <CDropdown variant="nav-item" style={{ color: "white" }}>
                  <CDropdownToggle
                    placement="bottom-end"
                    className="py-0"
                    caret={false}
                  >
                    <Whisper
                      placement="top"
                      controlId="control-id-hover"
                      trigger="hover"
                      speaker={<Tooltip>Filter Data .</Tooltip>}
                    >
                      <img
                        src={filtericon}
                        alt=""
                        style={{
                          height: "23px",
                          width: "35px",
                          marginTop: "-35px",
                          marginLeft: "-13px",
                        }}
                      />
                    </Whisper>
                  </CDropdownToggle>
                </CDropdown>
              </div> */}

              <div className="ml-auto me-4">
                <GlobalFilter
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  filter={globalFilter}
                  setFilter={setGlobalFilter}
                />
              </div>
            </div>
          </div>
        </div>

        <table {...getTableProps()} id="customers">
          <thead
            style={{
              position: "sticky",
              top: "135px",
              width: "100%",
              backgroundColor: "#f4f7fc",
              zIndex: "5",
            }}
          >
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("header")}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <img
                            src={updown_sort}
                            style={{ marginLeft: "5px" }}
                            alt=""
                          />
                        ) : (
                          <img
                            src={updown_sort}
                            style={{ marginLeft: "5px" }}
                            alt=""
                          />
                        )
                      ) : (
                        ""
                      )}
                      {column.isSorted ? (column.isSortedDesc ? "" : "") : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <NoDataFound rows={rows} />
        {/* ================= passing table page data too the pagintaion component ================= */}
        <Pagination
          data={rows}
          rows={rows}
          page={page}
          pageIndex={pageIndex}
          pageCount={pageCount}
          pageSize={pageSize}
          canPreviousPage={canPreviousPage}
          previousPage={previousPage}
          pageOptions={pageOptions}
          gotoPage={gotoPage}
          canNextPage={canNextPage}
          nextPage={nextPage}
        />
      </div>
    </Fragment>
  );
}

//Getting the state from the store
const mapStateToProps = (state) => {
  return {
    depositAmount: state.depositAmountData,
  };
};

//passing the userData in fetchData function and also dispatch method
const mapDispatchToProps = (dispatch) => {
  return {
    fetchData: (data) => dispatch(fetchDepositAmountListData(data)),
  };
};

//Connecting the component to our store
export default connect(mapStateToProps, mapDispatchToProps)(DepositAmountTable);
