import {
  ONLINE_FEES_LIST_TABLE_FAIL,
  ONLINE_FEES_LIST_TABLE_REQ,
  ONLINE_FEES_LIST_TABLE_SUCCESS,
} from "../../constants/actions";

const initialState = {
  loading: false,
  table_data: [],
  error: false,
};

const onlineFeesListTable = (state = initialState, action) => {
  switch (action.type) {
    case ONLINE_FEES_LIST_TABLE_REQ:
      return {
        ...state,
        loading: true,
      };
    case ONLINE_FEES_LIST_TABLE_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case ONLINE_FEES_LIST_TABLE_SUCCESS:
      return {
        error: false,
        loading: false,
        table_data: action.payload,
      };

    default:
      return state;
  }
};

export default onlineFeesListTable;
