var CryptoJS = require("crypto-js");

const encrypt = (msg) => {
  return CryptoJS.AES.encrypt(msg, "SSISM").toString();
};

const decrypt = (msg) => {
  var bytes = CryptoJS.AES.decrypt(msg, "SSISM");
  return bytes.toString(CryptoJS.enc.Utf8);
};

const storage = {
  setItem: (key, value) => {
    const encrypt_value = encrypt(value); //gjhgth5453ghhf
    localStorage.setItem(key, encrypt_value);
  },

  getItem: (key) => {
    const encrypt_value = localStorage.getItem(key); //hfhgfas5465
    if (!encrypt_value) {
      return false;
    }
    return decrypt(encrypt_value); //A
  },
};

const AES256_KEY = CryptoJS.enc.Hex.parse(
  "2856dd8e5302d8bf48df0fa61c87a4fe0d282aac995c6e59f0dd3b03384469f1"
);

export const decryptWithAES = (encryptedText) => {
  const [ivHex, encryptedData] = encryptedText.split(":");
  const iv = CryptoJS.enc.Hex.parse(ivHex);
  const encryptedWordArray = CryptoJS.enc.Hex.parse(encryptedData);
  const decrypted = CryptoJS.AES.decrypt(
    { ciphertext: encryptedWordArray },
    AES256_KEY,
    { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
  );
  return decrypted.toString(CryptoJS.enc.Utf8);
};

export default storage;
